.itr-main-heading {
    overflow: hidden;
    .to-rem ( border-radius, 0 5 5 0 );

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+94,f3f3f3+94 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #ffffff 94%, #f3f3f3 94%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#ffffff 94%,#f3f3f3 94%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 94%,#f3f3f3 94%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 ); /* IE6-9 */

    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
}

.itr-user-details {
    position: relative;
}

.itr-title {
    display: block;
    .to-rem ( padding, 22 );
    .to-rem ( border-radius, @border-radius );
    background-color: #FFF;

    .to-rem ( font-size, 26 );
}

.itr-photo {
    img, div {
        .to-rem ( max-width, 120 );
    }

    img {
        border-radius: 50%;
        .to-rem ( border, 4, solid #40ADEE );
        box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.25);
    }

    div {
        &.photo-placeholder {
            float: left;
            .to-rem ( width, 120 );
            .to-rem ( height, 120 );
            border-radius: 50%;
        }
    }
}

.itr-users-name {
    display: block;

    color: #3B65B1;

    .to-rem ( font-size, 20 );
}

.itr-plus-icon {
    cursor: pointer;
}

.itr-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.itr-print-btn {
    background-color: #DBF5DB;
    color: #7C7;
    border: 1px solid #FFF;

    &:hover {
        background-color: #7C7;
        color: #FFF;
    }
}

.itr-download-btn {
    background-color: #E0EBFF;
    color: #3B65B1;
    border: 1px solid #FFF;

    &:hover {
        background-color: #3B65B1;
        color: #FFF;
    }
}

.itr-role-instruction {
    float: left !important;
    width: 67%;
    margin-bottom: 6px !important;
    .l-grey;
    .lh;
    font-size: 14px !important;
    .em;
    .no-hyphenation;
}

#itr-occupational-competence_wrapper {
    thead {
        th {
            background-color: #F3F3F3;
        }
    }
}

#itr-occupational-competence-history_wrapper {
    opacity: 0;
    overflow: hidden;

    thead {
        th {
            background-color: #F3F3F3;
        }
    }
}

.section-heading {
    i, svg {
        &.itr-icon {
            .to-rem ( margin, 2 8 0 0 );
        }

        &.itr-plus-icon {
            .to-rem ( margin-top, 6 );
        }
    }
}
#itr-cpd {
    tr{
        td{
            p{
                margin:0px;
                line-height: 16px;
            }
        }
    }
}

#prev-deadline-date-holding {
    display: none;
}

.itr-deadline {
    display: inline-block;
    .to-rem ( width, 142 );

    text-align: left;

    button.edit {
        border: none;
        background: none;
    }

    .itr-deadline-date {
        display: inline-block;
        .to-rem ( width, 72 );
    }

    .itr-deadline-editing {
        .to-rem ( border, 1, solid #EEE );
        .to-rem ( border-radius, 3 );

        input[type="text"], button {
            display: inline;
            float: left;
            border: none;
            background: none;
        }

        input[type="text"] {
            .to-rem ( width, 65 );
        }

        button {
            .to-rem ( margin-top, 13 );
            .to-rem ( margin-left, 5 );
            padding: 0;
            color: #BBB;

            &.confirm {
                &:hover {
                    color: #7B7;
                }
            }
            &.reset {
                &:hover {
                    color: #FA0;
                }
            }
            &.cancel {
                &:hover {
                    color: #C00;
                }
            }
        }

        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }
};

.renewal-icon {
    display: inline-block;
    .to-rem ( width, 12 );
    .to-rem ( padding, 6 3 4 7 );
    .to-rem ( border-radius, 30 );

    background-color: #7C7;
    color: #FFF;

    font-family: "PT sans";
    font-style: italic;
}
