.ilt-session-adding-to {
    .to-rem ( padding, 8 );

    border-style: solid;
    .to-rem ( border-width, 1 );
    border-color: #3c65b1;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a87ce+0,5a87ce+5,4874bf+5,3c65b1+95,3c65b1+95,2855a3+95,2b5dad+100 */
    background: #5a87ce; /* Old browsers */
    background: -moz-linear-gradient(top,  #5a87ce 0%, #5a87ce 5%, #4874bf 5%, #3c65b1 95%, #3c65b1 95%, #2855a3 95%, #2b5dad 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #5a87ce 0%,#5a87ce 5%,#4874bf 5%,#3c65b1 95%,#3c65b1 95%,#2855a3 95%,#2b5dad 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #5a87ce 0%,#5a87ce 5%,#4874bf 5%,#3c65b1 95%,#3c65b1 95%,#2855a3 95%,#2b5dad 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a87ce', endColorstr='#2b5dad',GradientType=0 ); /* IE6-9 */

    color: #ffffff;

    .to-rem ( font-size, 18 );
    .to-rem ( line-height, 25 );
}

.ilt-sessions-trainees {
    .add-manual-user {

        .to-rem ( padding, 5 4 8 9 );
        .to-rem ( border, 1, solid #1d631d );
        background-color: #494;

        li {
            display: inline;
            margin-left: 0;

            #aum-first-name      { width: ~"calc(20% - 55px)"; }
            #aum-surname         { width: ~"calc(21% - 55px)"; }
            #aum-email           { width: ~"calc(31% - 55px)"; }
            #aum-company         { width: ~"calc(31% - 55px)"; }
            #add-manual-user-btn { .to-rem ( width, 170 ); }
        }

        .add-manual-user-btn {
            .to-rem ( padding, 12 );
            .to-rem ( margin-top, 1 );
        }
    }

    .manual-user {
        td {
            background-color: #FFF3EE;
        }
    }
}
