th {
  text-align: left;
}

.add-another-row {
  cursor: pointer;
}

// fluid fixed layouts

.oc-row, .oc-row input {
   width: 100%;
}

.fluid {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;

   width: 100%;
   float: left;
}

.fixed {
   float: right;
}

// - THEN IN THE PAGE SPECIFIC CSS SET UP THE WIDTHS LIKE THIS
// .ITEM-THAT-CONTAINS-LAYOUT .fluid {
//    margin-right: -300px;
//    padding-right: 310px;
// }
// .ITEM-THAT-CONTAINS-LAYOUT .fixed {
//    300px;
// }

.multi-select-padding {
  cursor: pointer;
  
  td {
    .to-rem ( padding, 14 ) !important;
  }
}
