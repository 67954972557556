.drive,
#files_to_upload
{
    border-color:#E9E9E9;
    background:#FFFFFF;
}

li.file
{
    border-color:#E9E9E9;
}

.file_name
{
    color:#999999;
}

.file_name a
{
    color:#55BBEE;
}

.file_error
{
    color:#DD0000;
}

.file_deleted
{
    color:#EE0000;
}

.uploading_progress
{
    background-color:#F2F2F2;
}

.uploading_progress_bar
{
    background-color:#77CC77;
    border-color:#55aa55;
}

.custom-file-input::before,
#browse-for-file-fake-btn {

}

.custom-file-input:hover::before {

}

/* BREADCRUMBS */

.breadcrumbs
{
    background:#FFFFFF;
    border-color:#E9E9E9;
    color:#999999;
}

.breadcrumbs li:not(:last-child)::after
{
    color:#999999;
}

.breadcrumbs li a
{
    color:#55BBEE;
}

/* /BREADCRUMBS */

form[name="upload_form"] {
    position: relative;
    .to-rem ( margin, 0 auto );
    display: block;
}

select[name="dd_site_select"]
{
    display:none;
}

.document_folder
{
    margin: 20px 3px 10px 0;
    float:left;
}

.mac_ff .document_folder
{
    margin: 18px 3px 10px 0;
}

.drive,
#files_to_upload
{
    list-style:none;
    border-radius:5px;
    border-width:1px;
    border-style:solid;
}

.drive
{
    display:block;
    margin:10px 0;
    border-bottom-width:4px;
}

#files_to_upload
{
    display:none;
}

li.file
{
    position:relative;
    border-bottom-width:1px;
    border-bottom-style:solid;
    overflow: hidden;
    padding:18px;
    margin-left: 0;

    -webkit-transition:.5s;
    -moz-transition:.5s;
    -o-transition:.5s;
    -ms-transition:.5s;
    transition:.5s;
}

li.file_normal_size
{
    padding:10px;
    height:52px;
}

.file_name
{
    float:left;
  width: 100%;
  margin: 9px 9px 9px -90px;
  padding-left: 90px;
}

.cb-select-file {
    float: left;
  .to-rem ( margin-top, 10 );
    .to-rem ( margin-right, 10 );
}

.selected-file {
    margin-left: 0;
    .to-rem ( border, 1, solid #DDD );
    .to-rem ( height, 52 );
  .to-rem ( padding, 10 );

    background-color: var(--color-white);

    &:first-child {
        .to-rem ( border-radius, 5 5 0 0 );
    }

    &:last-child {
        .to-rem ( border-radius, 0 0 5 5 );
    }

    &:not(:first-child) {
        border-top: none;
    }

    .remove-file-btn {
        .to-rem ( padding, 6 );
      .to-rem ( width, 30 );

        border: none;
        background-color: var(--color-white);
        color: #F30;

        &:hover {
            color: #D20;
        }
    }
}

.file_icon
{
    margin:9px;
    float:left;

    .fa {
        color: #F80;
    }

    .fa-folder {
        color: #5ABBEE;
    }

    .fa-file-excel-o {
        color: #217346;
    }

    .fa-file-word-o {
        color: #2B579A;
    }

    .fa-file-pdf-o {
        color: #D83B01;
    }
}

.uploading_progress
{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:5px;
}

.uploading_progress_bar
{
    position:absolute;
    top:0;
    left:0;
    width:0;
    height:4px;
    border-bottom-width:1px;
    border-bottom-style:solid;

    -webkit-transition:.5s;
    -moz-transition:.5s;
    -o-transition:.5s;
    -ms-transition:.5s;
    transition:.5s;
}

.delete_btn,
.history_btn,
.move_btn
{
    float:right;
    margin-left:5px;
}

:not(.win_ie) .custom-file-input {
    width:100px;
    position: absolute;
  .to-rem ( top, -30 );
}

.win_ie .custom-file-input {
    visibility:visible;
}

.mac_ff .custom-file-input,
.win_ff .custom-file-input
{
    .to-rem ( margin-top, 20 );
    .to-rem ( width, 101 );
    visibility: visible;
    opacity: 0;
    .to-rem ( height, 32 );
    border-style: solid;
    border-color: #227722;
    .to-rem ( border-width, 1 );
    .to-rem ( border-bottom-width, 4 );
    .to-rem ( font-size, 18 );
}

body:not(.win_ie) label[for="file_upload"] {
  position: relative;
    display: block;
  .to-rem ( padding, 0 );
  width: 100%;
  .to-rem ( height, 190 );
    .to-rem ( margin, 20 0 0 0 );
    .to-rem ( border-width, 2 );
    border-style: dashed;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    visibility: visible;

    background-color: #E3EAF3;
    border-color: #BBB;
    color: #444;
    .to-rem ( border-radius, 8 );

  text-align: center;
  
  .upload-instruction {
    position: absolute;
    width: 100%;
    height: 100%;

    .upload-instruction-icons {
      position: absolute;
      left: 50%;
      .to-rem ( margin-left, -27 );
      top: 40%;
      .to-rem ( margin-top, -25 );
      .to-rem ( width, 54 );
      .to-rem ( height, 50 );

      .fa-inbox {
        position: absolute;
        .to-rem ( left, 0 );
      }

      .fa-arrow-down {
        position: absolute;
        .to-rem ( top, 13 );
        .to-rem ( left, 20 );
      }
    }

    .upload-instruction-text {
      position: absolute;
      left: 50%;
      .to-rem ( margin-left, -103 );
      top: 60%;
      .to-rem ( margin-top, -8 );
    }
  }
}

#file_upload {
    position: absolute;
    .to-rem ( top, 0 );
    .to-rem ( left, 0 );
    height: 100%;
    opacity: 0;
    width: 100%;
}


.win_ie .custom-file-input::before,
.win_ie #browse-for-file-fake-btn
{
    visibility:hidden;
}

.custom-file-input:active::before {
    border-top-width:4px;
    border-bottom-width:1px;
}

.set_files{
    float: right;
    position: absolute;
    right: 10px;
    top: 10px;
}
/* BREADCRUMBS */

.breadcrumbs::before
{
    content:'YOU ARE HERE:\00a0\00a0';
}

.breadcrumbs
{
    display:table;
    float:left;
    padding:18px 15px 15px 15px;
    margin:15px 0;
    border-radius:4px;
    border-width:1px;
    border-style:solid;
    border-bottom-width:4px;
    list-style:none;
}

.breadcrumbs li
{
    display:inline;
    margin: 0;
}

.breadcrumbs li:not(:last-child)::after
{
    content:'\00a0\00a0»\00a0\00a0';
}

/* /BREADCRUMBS */

/* FILE SYSTEM CONTROLS */

.repos_control_bar
{

}

.repos_controls
{
    display:table;
    float:right;
    list-style:none;
    margin:10px 0;
}

.repos_controls li
{
    display:inline;
}

/* /FILE SYSTEM CONTROLS */

/* PDF VIEWER */

#read_confirmation
{
    margin-top:8px;
}

#read_confirmation label
{
    float:left;
    margin:4px 4px 0 0;
}

#read_confirmation_ts
{
    float:right;
}

/* /PDF VIEWER */

/* IMAGE VIEWER */

#image_viewer .image
{
    width:100%;
}

/* /IMAGE VIEWER */

/* FILE HISTORY */

#read_by_scroll_mask
{
    max-height:300px;
    overflow:scroll;
}

#file_history_popup
{
    width:700px;
}

#file_history_popup #read_by li
{
    list-style:none;
}

/* /FILE HISTORY */

.confirm-doc-read-label {
    .to-rem ( font-size, 14 );
}
