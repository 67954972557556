form,
.pseudo-form {

    ul {
        list-style: none;

        /*input,*/
        /*select:not(.custom-dd) {*/
            /*width: 100%;*/
            /*.to-rem ( padding, 5 );*/
            /*.to-rem ( font-size, 16 );*/
        /*}*/

        label {
            /*font-family: Calibri;*/
            .to-rem ( font-size, 16 );

            &.std-label {
                display: inline-block;
                .to-rem ( margin, 0 10 0 1 );

                color: @std-label-colour;

                text-align: left;

            }

            &.adjoined-label {
                .to-rem ( padding, 13 12 12 12 );
                background-color: #666;
                color: #D5D5D5;

                &.before {
                    .to-rem ( margin, 4 0 0 0 );
                }

                &.after {
                    position: relative;
                    .to-rem ( left, -6 );
                    .to-rem ( margin, 4 4 0 0 );
                }
            }

            &:not(.cb-label) {
                //.to-rem ( width, 300 );
                .to-rem ( margin, 10 0 0 3 );

                .to-rem ( font-size, 16 );
            }
        }

    }

    .cb-can-contact,
    label[for="cb-can-contact"] {
        position: fixed;
        z-index: -1000;
    }

    // button {
        //   .button ( #FA0, #E89403, #FFF, #E89403 );
    // }

    .form-section {
        .to-rem ( padding, 16 );
        .to-rem ( border, 1, solid #DDD );

        background-color: var(--color-white);

        &:not(:first-child) {
            .to-rem ( margin-top, 20 );
        }
    }

}

.cb-container {
    display: block;
    width: 100%;
    .to-rem ( padding, 10 );
    .to-rem ( border-bottom, 2, solid var(--secondary-colour) );
    background-color: #EEF4FF;

    input[type="checkbox"] {
        .to-rem ( margin-top, 0 );
    }
}

.grid-item {
    .cb-container {
        .to-rem ( margin-top, 4 );
    }
}

html.placeholder {
    label.hide-ph {
        // - HIDE PH MEANS WE'RE GOING TO HIDE IT IF PLACEHOLDERS ARE AVAILABLE
        // - THE EXCEPTION WILL BE FOR CONTROLS LIKE RADIOS AND CHECKBOXES
        display: none;
    }
}

.parsley-errors-list {
    display: block;

    .to-rem ( font-size, 12 );

    li {
        width: 100%;
        .to-rem ( padding, 12 );
        .to-rem ( margin, 0 );

        border-style: solid;
        .to-rem ( border-width, 1 );

        border-color: @form-error-border-colour;
        background-color: @form-error-bg-colour;
        color: var(--color-white);
    }
}

.single-parsley-error {
    width: 100%;
    .to-rem ( padding, 12 );
    .to-rem ( margin, 0 );

    border-style: solid;
    .to-rem ( border-width, 1 );

    border-color: @form-error-border-colour;
    background-color: @form-error-bg-colour;
    color: var(--color-white);

    .to-rem ( font-size, 12 );
}

/*input[type="text"],*/
/*input[type="password"],*/
/*input[type="number"],*/
/*input[type="email"],*/
/*input[type="tel"],*/
/*input[type="date"],*/
/*textarea:not(.auto-height) {*/
    /*.to-rem ( padding, 10 6 );*/
    /*border-style: solid;*/
    /*.to-rem ( border-width, 0 0 2 0 );*/
    /*.to-rem ( border-radius, 2 );*/
    /*.to-rem ( margin, 8 0 0 0 );*/

    /*background-color: transparent;*/
    /*border-color: @secondary-colour;*/
    /*color: @secondary-colour;*/
/*}*/


.custom-select-base {
    border-radius: 0;

    -webkit-appearance: none;
}

.custom-select-1 {
    .to-rem ( padding, 0 30 0 10 );
    .to-rem ( height, 41 );
    margin-top: 0;

    background-image: url('/assets/graphics/dropdown-arrow-666.png');
    background-repeat: no-repeat;
    background-position: ~"calc(100% - 10px)" center;

    .to-rem ( line-height, 21 );
}

input[disabled=""], input[disabled="disabled"],
select[disabled=""], select[disabled="disabled"],
textarea[disabled=""], textarea[disabled="disabled"] {
    &:not(.disabled-viewing) {
        background-color: #F4F4F4;
        color: #AAA;
        border-color: #AAA;
    }
}

textarea {
    &:not(.auto-height) {
        width: 100%;
    }
}

input.time-period {
    width: 5%;
    .to-rem ( margin-right, 4 );
}

select.time-period {
    .to-rem ( padding, 10 );
    .to-rem ( height, 41 );
}

.duration {
    input {
        .to-rem ( max-width, 70 );
        .to-rem ( height, 19 );
        box-sizing: content-box;
    }
    label {
        &:not(.hide-ph) {
            .to-rem ( margin-top, 4 );
            display: inline-block;
        }
    }
}

.table-form {
    .table-form-heading-label {
        .to-rem ( padding, 10 );
        .to-rem ( border-right-width, 1 );
        border-style: solid;
        border-color: #FFF;
        background-color: #DDD;
        color: #555;

        text-align: center;
        vertical-align: top;
    }

    .table-form-heading-input {
        .to-rem ( padding, 0 );

        background-color: #EEE;

        input, textarea {
            margin: 0;
            width: 100%;
            border: none;

            background: none;
            color: #666;

            &:focus {
                background-color: #F6F6F6;
            }
        }
    }

    .table-form-label {
        .to-rem ( padding, 10 );
        .to-rem ( border-right-width, 1 );
        border-style: solid;
        border-color: #FFF;
        background-color: #DDD;
        color: #555;

        text-align: center;
        vertical-align: top;
    }

    .table-form-input {
        .to-rem ( padding, 0 );

        background-color: #EEE;

        input, textarea {
            margin: 0;
            width: 100%;
            border: none;

            background: none;
            color: #666;

            &:focus {
                background-color: #F6F6F6;
            }
        }
    }
}

.form-hint {
    float: right;
    .to-rem ( border-radius, 20 );
    .to-rem ( padding, 26 );
    background-color: #E7E7E7;
    color: #666;
    font-style: italic;
    .to-rem ( line-height, 30 );
}

.archive-status-filter {
    position: relative;
    .to-rem ( padding, 18 );
    border-radius: var(--bs-border-radius);
    .to-rem ( border-width, 1 );
    border-style: solid;

    border-color: var(--bs-border-color);
    background-color: var(--color-white);
}

/**
 * CUSTOM DATE RANGE CONTROL
 */
.date-range-label {
    .to-rem ( font-size, 18 );
    font-weight: bold;
}

.date-range-filter-container {
    .to-rem ( padding, 9 5 10 5 );
    .to-rem ( border-radius, 4 );
    .to-rem ( border, 1, solid #D5D5D5 );

    .date-range-filter {
        .to-rem ( padding, 6 );
        color: #AAA;
        .to-rem ( font-size, 13 );

        input {
            width: 38%;
            border: none;

            color: #C00;

            font-weight: bold;
        }
    }
}

.tree-selected {
    .to-rem ( margin-top, 6 );
    padding: 0;
    .to-rem ( border-width, 1 );
    border-style: solid;
    border-color: #D5D5D5;

    div {
        float: left;
        .to-rem ( padding, 12 );
    }

    button {
        float: right;
        .to-rem ( padding, 12 );
        border: none;
        background-color: #7B7;
        color: #FFF;
    }
}

li.fl-ph {
    position: relative;

    input {
        border: none;
        border-bottom: 2px solid var(--secondary-colour);

        &:focus {
            outline: none !important;
        }
    }

    label {
        position: absolute;
        pointer-events: none;
        top: 12px;
        left: 28px;
        color: #555;
        font-size: 16px;
        transition: .3s ease all;

        &.floating {
            top: -18px;
            left: 26px;
            color: #AAA;
        }
    }

    &.pl0 {
        label {
            left: 0;

            &:floating {
                left: -2px;
            }
        }
    }
}
