.forgotten-password-confirmed {
  main {
    .to-rem ( padding, 20 );
    .to-rem ( border-radius, 8 );
    .to-rem ( border, 1, solid #E0E0E0 );
    background-color: var(--color-white);

    p {
      &:last-child {
        .to-rem ( margin-bottom, 0 );
      }
    }
  }
}
