input[disabled=""],
input[disabled="disabled"],
select[disabled=""],
select[disabled="disabled"],
textarea[disabled=""],
textarea[disabled="disabled"],
button[disabled="disabled"] {
  cursor: no-drop !important;
}

button.disabled {
  cursor: no-drop !important;
}

label {
    font-size: 14px;
}

textarea.auto-height {
  box-sizing: border-box;
  .to-rem ( min-height, 38 );
  overflow-x: hidden;
}

// - SWITCH

.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  &.standalone {
    .to-rem ( width, 52 );
  }
  .to-rem ( height, 20 );

  color: #3864AF;

  input {
    display: none;

    &:checked~label::before {
      border-color: #09F;
    }

    &:checked~label::after {
      background-color: #09F;
      .to-rem ( right, 4 );
    }
  }

  label {
    position: absolute;
    width: 100%;
    margin-top: 0;
    .to-rem ( padding-top, 7 );
    font-family: Calibri, Verdana, sans-serif;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      .to-rem ( width, 50 );
      .to-rem ( height, 26 );
      .to-rem ( border-radius, 14 );
      
      .to-rem ( border-width, 1 );
      border-style: solid;
      border-color: #BBB;

      background-color: #FFF;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      .to-rem ( top, 4 );
      .to-rem ( right, 28 );
      .to-rem ( width, 20 );
      .to-rem ( height, 20 );
      .to-rem ( border-radius, 10 );

      background-color: #BBB;

      transition: 0.3s;
    }
  }
}

// - END SWITCH
