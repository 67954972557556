body {
    position: relative;

    background-color: @bg-colour;

    font-family: 'Lato', 'Gill Sans';
    font-size: 14px;

    .background-content > header {
        > .inner {
            /*.to-rem ( max-width, 978 );*/
            .to-rem ( height, 80 );

            @media (min-width: 1025px) {
                .to-rem ( height, 120 );
            }

            .header-logo {
                display: block;
                float: left;
                .to-rem ( width, 160 );
                .to-rem ( height, 37 );
                .to-rem ( margin, 20 0 0 0 );

                background-size: 100%;
                background-repeat: no-repeat;
                text-indent: -9999px;

                @media (min-width: 1025px) {
                    .to-rem ( width, 307 );
                    .to-rem ( height, 71 );
                }
            }
        }

        label[for="cb-sub-menu"] {
            display: block;

            color: #333;

            .to-rem ( font-size, 30 );

            @media (min-width: 1025px) {
                display: none;
            }
        }

        #cb-sub-menu {
            position: absolute;
            visibility: hidden;
        }

        #cb-sub-menu:checked + nav.main-nav {
            display: block;
        }

        #cb-sub-menu:not(:checked) + nav.main-nav {
            display: none;

            @media (min-width: 1025px) {
                display: block;
            }
        }
    }
}

.inner {
    position: relative;
    margin-left:  auto;
    margin-right: auto;
}

.separator {
    position: relative;
    .to-rem ( top, 3 );

    color: #DDD;

    .to-rem ( font-size, 22 );
    font-weight: bold;
}

nav.main-nav {
    position: relative;
    width: 100%;
    z-index: @Z-main-nav;
    .to-rem ( padding, 0 30 );

    background-color: @nav-link-bg-colour;

    border-color: @nav-link-border-colour;
    border-style: solid;
    .to-rem ( border-width, 0 0 2 0 );

    @media (min-width: 1025px) {
        .to-rem ( height, 42 );
    }

    // - FIRST TIER
    > ul {

        /*.to-rem ( max-width, 978 );*/

        //text-align: justify;// - GET THIS TO KICK IN TO JUSTIFY WHEN THE WIDTH OF ALL LINKS IS > SAY 90%, DO THIS IN JQUERY
        -ms-text-justify: distribute-all-lines;
        text-justify: distribute-all-lines;

        > li {
            background-color: #F7F7F7;

            @media (min-width: 1025px) {
                background-color: transparent;
            }

            a {
                color: @nav-link-text-colour;
            }

        }

        // - ANY LI OF FIRST TIER
        li {
            width: auto;
            vertical-align: top;
            display: inline-block;
                     *display: inline;
                     zoom: 1;

                     // - ANY DIRECT CHILD UL OF ANY LI
                     > ul {
                         width: 100%;

                         @media (min-width: 768px) {
                             position: absolute;
                             left: 0;
                             top: 100%;
                             width: auto;
                         }

                         a {
                             .to-rem ( padding-left, 32 );

                             @media (min-width: 768px) {
                                 .to-rem ( padding-left, 16 );
                             }
                         }
                     }
                 }

                 > li {
                     &:first-child {
                         > a {
                             @media (min-width: 768px) {
                                 padding-left: 0;
                             }
                         }
                     }

                     &:last-child {
                         > a {
                             @media (min-width: 768px) {
                                 padding-right: 0;
                             }
                         }
                     }
                 }

                 a {
                     width: 100%;
                     .to-rem ( margin, 0 -2 );

                     .to-rem ( border-bottom, 1, solid #EEE );

                     @media (min-width: 768px) {
                         width: auto;
                         border-bottom: none;
                     }

                     &:first-child {
                         margin-left: 0;
                     }

                     &:last-child {
                         margin-right: 0;
                     }
                 }
             }

             // - ALL TIERS
             ul {
                 .to-rem ( margin, 0 auto );
                 list-style: none;

                 // - ALL DERIVATIVE LI's
                 li {
                     .to-rem ( margin, 0 );
                     .to-rem ( padding, 0 );
                     display: block;
                     position: relative;

                     font-weight: 900;

                     @media (min-width: 768px) {
                         display: inline-block;
                     }

                     // - ALL DERIVATIVE ULS
                     ul {
                         display: inline-block;

                         .to-rem ( min-width, 180 );

                         background-color: @sub-menu-bg-colour;

                         @media (min-width: 768px) {
                             visibility: hidden;
                         }

                         li {
                             display: block;

                             a {
                                 width: 100%;
                                 .to-rem ( line-height, 18 );
                             }
                         }
                     }

                     > ul {
                         @media (min-width: 768px) {
                             .to-rem ( margin-top, -2 );
                         }

                         text-align: left;
                     }

                     &:hover {
                         > ul {

                             visibility: visible;
                         }
                     }
                 }
             }

             a {
                 display: inline-block;
                 .to-rem ( padding, 14 );

                 color: @nav-link-text-colour;

                 text-decoration: none;
                 .to-rem ( font-size, 14 );

                 &:hover {
                     color: @nav-link-text-hover-colour;
                 }
             }

             .help-sub-menu {
                 min-width: auto;
                 .to-rem ( width, 110 );
                 left: inherit;
                 right: 0;
             }

             .stretcher {
                 width: 100%;
                 display: inline-block;
                 font-size: 0;
                 line-height: 0;
             }
         }

         nav.main-nav {
             > ul {
                 > li {
                     > ul {
                         > li {
                             > ul {
                                 top: 0;
                                 left: 100%;
                                 // border-color: #EEE;
                                 // border-style: solid;
                                 // .to-rem ( border-width, 2 2 2 0 );
                             }
                         }
                     }
                 }
             }
         }

         nav.toolbar {
             position: absolute;
             display: none;
             .to-rem ( top, 0 );
             .to-rem ( right, 20 );
             .to-rem ( padding, 6 );

             background-color: @toolbar-bg-colour;
             color: @toolbar-text-colour;

             border-color: @toolbar-border-colour;
             border-style: solid;
             .to-rem ( border-width, 0 2 4 2 );
             .to-rem ( border-radius, 0 0 4 4 );

             @media (min-width: 768px) {
                 display: block;
             }

             .signed-in-as {
                 .to-rem ( padding-left, 6 );
             }

             a {
                 display: inline-block;
                 .to-rem ( padding, 5 );
                 .to-rem ( line-height, 6 );
                 .to-rem ( margin, 3 );
                 color: @toolbar-text-colour;

                 text-decoration: none;

                 &:hover {
                     color: @toolbar-text-hover-colour;
                 }
             }
         }

         // - LANGUAGES
         .language-selector {
             background-color: var(--color-white);

             li {
                 cursor: pointer;
                 &:hover {
                     color: @toolbar-text-hover-colour;
                 }
             }
         }


         // - FOOTER

         body {
             .background-content > footer {
                 .to-rem ( margin-top, 16 );
                 .to-rem ( padding, 60 );

                 background-color: var(--color-white);
                 color: #999;

                 text-align: center;

                 ul {
                     .to-rem ( margin, 0 0 16 0 );

                     li {
                         display: inline;
                         .to-rem ( margin, 0 5 );
                     }
                 }

                 a {
                     color: @footer-link-colour;

                     text-decoration: none;
                     font-weight: 900;
                 }

                 .copyright {
                     width: 55%;
                     margin-left: auto;
                     margin-right: auto;

                     .to-rem ( font-size, 15 );
                 }

                 .company-details {
                     .to-rem ( font-size, 13 );
                 }

                 .version-no {
                     color: #CCC;

                     .to-rem ( font-size, 12 );
                 }
             }
         }
