.home-page {

  nav.main-nav {
    border-color: #D8D8D8;
    > ul {
      border-color: #D8D8D8;
      > li {
        > ul {
          border-color: #D8D8D8;
          > li {
            > ul {
              border-color: #D8D8D8;
            }
          }
        }
      }
    }
  }

  > main {
    background-color: #292929;
  }

  .KSPs {
    float: left;
    width: 100%;
    margin-bottom: 0;
    background-color: #292929;

    .KSP {
      display: inline;
      position: relative;
      float: left;
      width: 100%;
      .to-rem ( height, 275 );
      margin: 0 0.1%;
      .to-rem ( padding, 10 );
      .to-rem ( border-width, 8 0 0 0 );
      border-style: solid;

      cursor: pointer;

      @media (min-width: 768px) and (max-width: 959px) {
        width: 49.8%;
      }

      @media (min-width: 960px) {
        width: 24.8%;
      }

      a.more-btn {
        position: absolute;
        display: block;
        .to-rem ( right, 18 );
        .to-rem ( bottom, 14 );
        .to-rem ( padding, 6 3 6 6 );

        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
          .to-rem ( height, 28 );
          .to-rem ( border-width, 14 7 );
          border-style: solid;
        }
      }

    }

    .KSP-1 {
      background-color: #333;
      color: var(--color-white);
      border-color: @diamond-blue;

      &:hover {
        background-color: #2b4b82;
      }

      h3 {
        color: var(--color-white);
        .to-rem ( font-size, 31 );
      }

      a.more-btn {
        background-color: @diamond-blue;
        color: var(--color-white);

        &:hover {
          background-color: lighten(@diamond-blue, 10%);
          &::before {
            border-color: transparent transparent transparent lighten(@diamond-blue, 10%);
          }
        }

        &::before {
          border-color: transparent transparent transparent @diamond-blue;
        }
      }
    }

    .KSP-2 {
      background-color: #333;
      color: var(--color-white);
      border-color:  @diamond-red;

      &:hover {
        background-color: #ab1719;
      }

      h3 {
        color: var(--color-white);
        .to-rem ( font-size, 31 );
      }

      a.more-btn {
        background-color: @diamond-red;
        color: var(--color-white);

        &:hover {
          background-color: lighten(@diamond-red, 10%);
          &::before {
            border-color: transparent transparent transparent lighten(@diamond-red, 10%);
          }
        }

        &::before {
          border-color: transparent transparent transparent @diamond-red;
        }
      }
    }

    .KSP-3 {
      background-color: #333;
      color: var(--color-white);
      border-color: #F7C61F;

      &:hover {
        background-color: #DEA215;
        color: var(--color-white);

        h3 {
          color: var(--color-white);
        }
      }

      h3 {
        color: var(--color-white);
        .to-rem ( font-size, 31 );
      }

      a.more-btn {
        background-color: #F7C61F;
        color: var(--color-black);

        &:hover {
          background-color: lighten(#F7C61F, 12%);
          &::before {
            border-color: transparent transparent transparent lighten(#F7C61F, 12%);
          }
        }

        &::before {
          border-color: transparent transparent transparent #F7C61F;
        }
      }
    }

    .KSP-4 {
      background-color: #333;
      color: var(--color-white);
      border-color: @diamond-white;

      &:hover {
        background-color: #d1d1d1;
        color: var(--color-black);

        h3 {
          color: var(--color-black);
        }
      }

      h3 {
        color: var(--color-white);
        .to-rem ( font-size, 31 );
      }

      a.more-btn {
        background-color: var(--color-white);
        color: var(--color-black);

        &:hover {
          background-color: var(--color-black);
          color: var(--color-white);
          &::before {
            border-color: transparent transparent transparent var(--color-black);
          }
        }

        &::before {
          border-color: transparent transparent transparent var(--color-white);
        }
      }
    }
  }

  .section-3 {
    float: left;
    .to-rem ( padding, 30 );
    .to-rem ( border-top, 12, solid #444 );
    color: #888;
    background-color: #222;

    header * {
      color: var(--color-white);
    }

    main {
      float: left;
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }
    }

    .section-3-img {
      float: right;
      .to-rem ( margin-top, -45 );
      width: 100%;

      @media (min-width: 960px) {
        width: auto;
      }
    }

    a.more-btn {
      position: absolute;
      display: block;
      .to-rem ( right, 28 );
      .to-rem ( bottom, 24 );
      .to-rem ( padding, 6 3 6 6 );

      background-color: @diamond-blue;
      color: var(--color-white);

      text-decoration: none;

      cursor: pointer;

      &:hover {
        background-color: lighten(@diamond-blue, 10%);
        &::before {
          border-color: transparent transparent transparent lighten(@diamond-blue, 10%);
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        .to-rem ( height, 40 );
        .to-rem ( border-width, 20 9 );
        border-style: solid;
        border-color: transparent transparent transparent @diamond-blue;
      }
    }
  }
}
