.sitemap {
  .section {
    .to-rem ( margin, 0 0 60 0 );

    h1 {
      margin: 0;
      .to-rem ( padding, 16 );
      background-color: #2d4f8a;
      color: var(--color-white);
      .to-rem ( font-size, 26 );
    }

    div {
      .to-rem ( padding, 16 );
      background-color: @diamond-blue;
      color: var(--color-white);

      li {
        .to-rem ( margin-top, 5 );
        list-style: initial;
      }

      a {
        color: var(--color-white);
      }
    }
  }
}
