.add-record-form,
.edit-record-form {
  .to-rem ( max-width, 700 );
}

.add-record-fields-host,
.edit-record-fields-host,
.view-record-fields-host {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

.popup {
  .add-record-fields-host,
  .edit-record-fields-host {
    .to-rem ( height, 280 );
  }

  .view-record-fields-host {
    .to-rem ( height, 350 );
  }
}

.select-with-add-btn {
  display: inline !important;
  width: 77%;
}

.select-add-btn {
  .positive-btn;
  .grid-item;
  float: right !important;

  width: 22%;

  .to-rem ( margin-top, 4 );
  .to-rem ( padding, 8 7 7 7 );
}
