button[name="btn-sign-in"] {
    .to-rem ( padding, 12 6 );
    width: 100%;
    .to-rem ( font-size, 20 );
}

.forgot-password-aid-memoir {
    overflow: hidden;
    .to-rem ( height, 64 );
    .to-rem ( margin-top, 52 );
    .to-rem ( padding, 16 );
    .to-rem ( border-radius, 2 );
    .to-rem ( border, 1, solid #CCC );
    background-color: #F9F9F9;

    .forgot-your-password-btn {

    }

    form {
        width: 100%;
        opacity: 0;

        .forgot-p-handle-wrapper {
            display: inline-block;
            .to-rem ( margin-left, -40 );
            .to-rem ( padding-left, 40 );
            width: 100%;
        }

        .forgot-password-email {
            width: 100%;
        }

        .forgot-password-submit-btn {
            float: right;
            .to-rem ( width, 36 );
            .to-rem ( margin-top, 9 );
        }

        .forgot-password-guidance {
            .to-rem ( margin, 14 0 9 0 );
            .to-rem ( line-height, 16 );

            color: #06A;

            font-weight: 100;
            .to-rem ( font-size, 12 );
        }
    }
}
