@border-radius-size : 0;

.published-courses {
  .category-name {
    display: inline-block;
    .to-rem ( margin-top, 30 );
    .to-rem ( margin-bottom, 20 );

    .to-rem ( padding, 10 );
    .to-rem ( border-left, 8, solid );

    text-transform: uppercase;

    &.petroleum {
      color: var(--color-white);
      border-color: #698ece;
      background-color: @diamond-blue;

      .course-term {
        color: #a2b9e1;
      }
    }

    &.fire-hazards, &.fire-safety {
      color: var(--color-white);
      border-color: #ea6264;
      background-color: @diamond-red;

      .course-term {
        color: #a2b9e1;
      }
    }

    &.health-and-safety {
      color: var(--color-white);
      border-color: #f8d55e;
      background-color: @diamond-yellow;

      .course-term {
        color: #fbe8a8;
      }
    }

    &.toolbox-talks {
      color: var(--color-white);
      border-color: #b3b3b3;
      background-color: #333;

      .course-term {
        color: #808080;
      }
    }
  }

  .empty-category {
    .to-rem ( margin, 20 0 );
    .to-rem ( padding, 20 );
    .to-rem ( border-top-width, 8 );
    border-style: solid;

    color: #FFF;

    .to-rem ( font-size, 18 );
    text-align: center;

    &.petroleum {
      border-color: #698ece;
      background-color: @diamond-blue;
    }

    &.fire-hazards, &.fire-safety {
      border-color: #ea6264;
      background-color: @diamond-red;
    }

    &.health-and-safety {
      border-color: #f8d55e;
      background-color: @diamond-yellow;
    }

    &.toolbox-talks {
      border-color: #b3b3b3;
      background-color: #333;
    }
  }
}

.published-course {
  .to-rem ( margin, 20 0 );
  .to-rem ( border-radius, @border-radius-size );

  header {
    .to-rem ( border-radius, @border-radius-size @border-radius-size 0 0 );
    .to-rem ( border-width, 8 0 0 0 );
    border-style: solid;

    h3 {
      .to-rem ( padding, 16 );
      .to-rem ( margin, 0 );

      color: var(--color-white);

      .to-rem ( font-size, 24 );
    }
  }

  main {
    .to-rem ( padding, 18 );
    .to-rem ( margin, 0 );
    .to-rem ( border-radius, 0 0 @border-radius-size @border-radius-size );

    background-color: var(--color-white);
    .to-rem ( border-width, 2 );
    border-style: solid;
    border-color: #E6E6E6;
    border-top: none;

    .to-rem ( font-size, 14 );

    .elearning-price {
      display:none;// - REMOVE THIS WHEN WE GET BASKET UP AND RUNNING AGAIN
    }
    .course-list-overview {
      width: 75%;
    }

    p {
      .to-rem ( line-height, 18 );
      .to-rem ( margin, 16 0 );

      &:first-child {
        margin-top: 0;
      }
    }

    .elearning-launch-btn {

      position: relative;
      .to-rem ( padding, 9 );

      text-decoration: none;

      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        .to-rem ( left, 62 );
        .to-rem ( height, 22 );

        .to-rem ( border-width, 17 8 );
        border-style: solid;
      }
    }

    .elearning-more-info {
      .to-rem ( padding, 9 );

      text-decoration: none;
    }
  }

  &.blue {
    header {
      background-color: @diamond-blue;
      border-color: #698ece;
    }

    main {
      color: #070d17;
      background-color: #FFF;//tried 40%

      .elearning-launch-btn {
        background-color: #069;
        color: var(--color-white);

        &:after {
          border-color: transparent transparent transparent #069;
        }

        &:hover {
          background-color: #279EDA;
          color: var(--color-white);

          &:after {
            border-color: transparent transparent transparent #279EDA;
          }
        }
      }

      .elearning-more-info {
        background-color: #069;
        color: var(--color-white);

        &:hover {
          background-color: #279EDA;
          color: var(--color-white);
        }
      }
    }

    //border-color: #070d17;
  }

  &.red {
    header {
      background-color: @diamond-red;
      border-color: #ea6264;
    }

    main {
      color: #2d0607;
      background-color: #FFF;

      .elearning-launch-btn {
        background-color: #b4181a;
        color: var(--color-white);

        &:after {
          border-color: transparent transparent transparent #b4181a;
        }

        &:hover {
          background-color: @diamond-red;
          color: var(--color-white);

          &:after {
            border-color: transparent transparent transparent @diamond-red;
          }
        }
      }

      .elearning-more-info {
        background-color: #b4181a;
        color: var(--color-white);

        &:hover {
          background-color: @diamond-red;
          color: var(--color-white);
        }
      }
    }
  }

  &.yellow {
    header {
      background-color: @diamond-yellow;
      border-color: #f8d55e;

      h3 {
        color: var(--color-black);
      }
    }

    main {
      color: #3b2f03;
      background-color: #FFF;

      .elearning-launch-btn {
        background-color: @diamond-yellow;
        color: var(--color-black);

        &:after {
          border-color: transparent transparent transparent @diamond-yellow;
        }

        &:hover {
          background-color: #f9dc77;
          color: var(--color-black);

          &:after {
            border-color: transparent transparent transparent #f9dc77;
          }
        }
      }

      .elearning-more-info {
        background-color: @diamond-yellow;
        color: var(--color-black);

        &:hover {
          background-color: #f9dc77;
          color: var(--color-black);
        }
      }
    }
  }

  &.white {
    header {
      background-color: #333;
      border-bottom: #b3b3b3;

      h3 {
        color: var(--color-white);
      }
    }

    main {
      color: var(--color-black);
      background-color: var(--color-white);

      .elearning-launch-btn {
        background-color: var(--color-black);
        color: var(--color-white);

        &:after {
          border-color: transparent transparent transparent var(--color-black);
        }

        &:hover {
          background-color: #4d4d4d;
          color: var(--color-white);

          &:after {
            border-color: transparent transparent transparent #4d4d4d;
          }
        }
      }

      .elearning-more-info {
        background-color: var(--color-black);
        color: var(--color-white);

        &:hover {
          background-color: #4d4d4d;
          color: var(--color-white);
        }
      }
    }
  }
}

.course-overview {
  .course-title * {
    .to-rem ( padding, 10 );
    .to-rem ( margin, 0 );
    background-color: var(--color-black);
    color: var(--color-white);
  }

  .video-js {
    border-top-color: @diamond-blue;
    .to-rem ( border-top-width, 10 );
  }

  .co-section {
    display: none;

    li {
      .to-rem ( margin, 5 18 );
      list-style: initial;
    }
  }

  .co-call-to-action {
    .to-rem ( margin, 20 0 0 0 );
    .to-rem ( padding, 30 );
    .to-rem ( border-width, 12 3 3 3 );
    border-style: solid;

    border-color: #6B6;
    background-color: #7C7;
    color: var(--color-white);

    h1, h2, h3, h4, h5, h6 {
      color: var(--color-white);
      .to-rem ( font-size, 30 );
    }

    input, select {
      .to-rem ( border-radius, 4 );
      .to-rem ( height, 45 );
      .to-rem ( margin, 0 );

      .to-rem ( border, 2, solid #6B6 );
    }

  }
}
