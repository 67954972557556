.breadcrumbs-container {
    display: inline-block;
    .to-rem ( padding, 20 );
    .to-rem ( border-radius, 20 );
    background-color: @secondary-colour;

    a, i, svg {
        .to-rem ( margin, 0 5 );
    }

    a {
        color: #FFF;

        &:hover {
            color: @primary-colour;
        }
    }

    li {
        margin-left: 0;
        display: inline-block;
    }

    i, svg {
        color: #FFF;
    }
}
