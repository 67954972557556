body {
    margin: 0;
    padding: 0;
}

.athena,
.knowledge-check {
    * {
        -moz-hyphens: none !important;
        -ms-hyphens: none !important;
        -webkit-hyphens: none !important;
        hyphens: none !important;
    }

    .content-begin {
        display: none;
    }
    //   __    ___       _______       __        _______   _____  ___
    //  |" \  |"  |     /"     "|     /""\      /"      \ (\"   \|"  \
    //  ||  | ||  |    (: ______)    /    \    |:        ||.\\   \    |
    //  |:  | |:  |     \/    |     /' /\  \   |_____/   )|: \.   \\  |
    //  |.  |  \  |___  // ___)_   //  __'  \   //      / |.  \    \. |
    //  /\  |\( \_|:  \(:      "| /   /  \\  \ |:  __   \ |    \    \ |
    // (__\_|_)\_______)\_______)(___/    \___)|__|  \___) \___|\____\)

    &.ilearn {

        overflow: hidden;

        .to-rem ( max-height, 716 );

        border-color: var(--color-black);

        .std-btn {
            position: absolute;
            .to-rem ( right, 10 );
            .to-rem ( bottom, 10 );
            .to-rem ( padding, 10 );
            .to-rem ( border-radius, 5 );

            cursor: pointer;

            color: var(--color-white);

            border: none;
            border-left-style: solid;
            .to-rem ( border-left-width, 2 );

            border-left-color: #247bfd;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c2c2c+0,2c2c2c+50,191919+50 */
            background: #2c2c2c; /* Old browsers */
            background: -moz-linear-gradient(top,  #2c2c2c 0%, #2c2c2c 50%, #191919 50%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2c', endColorstr='#191919',GradientType=0 ); /* IE6-9 */

            .to-rem ( letter-spacing, 0.7 );
        }

        .athena-close-btn {
            .to-rem ( padding, 2 5 );
            .to-rem ( border-radius, 3 );
            float: right;
            border: none;

            background-color: transparent;
            color: var(--color-white);

            .to-rem ( font-size, 12 );
            text-transform: uppercase;

            &:hover {
                background-color: #861D00;
            }
        }

        #loading-spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            .to-rem ( padding, 20 );
            .to-rem ( border-radius, 20 );

            color: #FFF;
            background-color: rgba(0,0,0,0.6);
        }

        .athena-header {
            position: absolute;
            width: 100%;
            .to-rem ( height, 80 );
            .to-rem ( padding-left, 10 );

            background-color: var(--color-black);

            .heading {
                position: absolute;
                .to-rem ( top, 32 );
                .to-rem ( right, 10 );

                .to-rem ( font-size, 20 );
            }

            .sub-heading {
                position: absolute;
                .to-rem ( top, 56 );
                .to-rem ( right, 10 );

                .to-rem ( font-size, 14 );
            }

            .assessment-timer {
                display: none;
                position: absolute;
                .to-rem ( top, 58 );
                .to-rem ( right, 5 );
                color: #FFF;
            }
        }

        footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            .to-rem ( height, 60 );
            .to-rem ( padding, 10 );
            z-index: @Z-athena-footer;

            background-color: var(--color-black);

            .menu-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( left, 15 );
                opacity: 1;

                cursor: pointer;

                &.disabled {
                    opacity: .6;
                }
            }

            .full-screen-btn {
                position: absolute;
                .to-rem ( top, 17 );
                .to-rem ( left, 650 );
                .to-rem ( padding, 5 7 );
                .to-rem ( border-radius, 3 );

                .to-rem ( border, 1, solid #1B1B1B );

                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c2c2c+0,292929+50,1b1b1b+51,191919+99,1b1b1b+100 */
                background: #2c2c2c; /* Old browsers */
                background: -moz-linear-gradient(top, #2c2c2c 0%, #292929 50%, #1b1b1b 51%, #191919 99%, #1b1b1b 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #2c2c2c 0%,#292929 50%,#1b1b1b 51%,#191919 99%,#1b1b1b 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #2c2c2c 0%,#292929 50%,#1b1b1b 51%,#191919 99%,#1b1b1b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2c', endColorstr='#1b1b1b',GradientType=0 ); /* IE6-9 */
                color: #FFF;
            }

            .back-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 166 );
                opacity: 1;

                cursor: pointer;

                &.disabled {
                    opacity: .6;
                }
            }

            .replay-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 90 );
                opacity: 1;

                cursor: pointer;

                &.disabled {
                    opacity: .6;
                }
            }

            .next-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 15 );
                opacity: 1;

                cursor: pointer;

                &.disabled {
                    opacity: .6;
                }
            }
        }

        *[athena-tooltip]:hover .athena-tooltip {
            opacity: 1;
        }

        .athena-tooltip {
            display: inline;
            position: absolute;
            bottom: 125%;
            .to-rem ( padding, 4 );
            .to-rem ( border-radius, 8 );
            .to-rem ( border-width, 1 1 3 1 );
            border-style: solid;
            border-color: #4D8AE7;
            background-color: #000;
            color: #ffffff;
            .to-rem ( font-size, 12 );
            z-index: 100;
            box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
            opacity: 0;

            transition-property: opacity;
            transition-duration: .3s;

            &::before {
                content: '';
                position: absolute;
                z-index: 10;
                .to-rem ( bottom, -17 );
                right: ~"calc(50% - 7px)";
                .to-rem ( height, 0 );
                .to-rem ( border-width, 7 );
                border-style: solid;
                border-color: #4D8AE7 transparent transparent transparent;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 5;
                .to-rem ( bottom, -17 );
                right: ~"calc(50% - 7px)";
                .to-rem ( height, 0 );
                .to-rem ( border-width, 7 );
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
            }
        }

        /* TOOLTIPS SPECS START */

        .athena-tooltip {
            //left: NEEDS TO BE CENTRAL TO ITS PARENT;// - TAKEN CARE OF IN JS
            .to-rem ( width, 112 );
        }

        /* TOOLTIPS SPECS START */

        .click-next-to-continue {
            position: absolute;
            .to-rem ( bottom, -90 );
            .to-rem ( right, 20 );
            .to-rem ( padding, 12 );

            .to-rem ( border-radius, 10 );
            .to-rem ( border, 2, solid #4D8AE7 );
            .to-rem ( border-bottom, 5, solid #4D8AE7 );

            background-color: var(--color-black);
            color: var(--color-white);

            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

            &::before {
                content: '';
                position: absolute;
                z-index: 10;
                .to-rem ( bottom, -17 );
                .to-rem ( right, 25 );
                .to-rem ( height, 0 );
                .to-rem ( border-width, 7 );
                border-style: solid;
                border-color: #4D8AE7 transparent transparent transparent;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 5;
                .to-rem ( bottom, -17 );
                .to-rem ( right, 25 );
                .to-rem ( height, 0 );
                .to-rem ( border-width, 7 );
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
            }

            &.in-front {
                z-index: @Z-athena-click-next-in-front;
            }

            &.behind {
                z-index: @Z-athena-click-next-behind;
            }

            .click-next-text {
                -webkit-user-select: none; /* Chrome/Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+ */

                /* Rules below not implemented in browsers yet */
                -o-user-select: none;
                user-select: none;
            }
        }

        .athena-content {
            position: absolute;
            display: none;
            opacity: 0;
            .to-rem ( width, 900 );
            height: 100%;
            .to-rem ( top, 80 );
            .to-rem ( margin, 30 20 );

            .slide-title {
                position: absolute;
                top: 40%;
                width: 100%;
                text-align: center;
            }
        }

        .athena-menu {
            position: absolute;
            .to-rem ( top, 80 );
            .to-rem ( margin-top, 30 );

            .menu-list-container {
                .to-rem ( max-width, 900 );
                .to-rem ( height, 500 );
                margin: 0 auto;
                overflow-y: scroll;
            }

            li.menu-item {
                list-style: none;
                float: left;
                .to-rem ( margin-bottom, 16 );
            }

            li.menu-item:last-child {
                margin-bottom: 0;
            }

            .menu-item-text-bar {
                float: left;
                .to-rem ( width, 521 );
                .to-rem ( height, 42 );
                .to-rem ( padding, 13 0 0 48 );
                background-image: url("/assets/graphics/athena-skins/ilearn/MenuItemTextBar.png");
                background-position: 0 0;
                background-repeat: no-repeat;
                color: #FFF;
                font-family: calibri;

                .menu-item-text {
                    font-family: "PT Sans";
                    white-space: nowrap;
                }

                .athena-menu-icon {
                    position: relative;
                    float: right;
                    .to-rem ( margin-top, 1 );
                    .to-rem ( margin-right, 16 );
                    color: var(--color-white);
                    .to-rem ( font-size, 14 );

                    &.fa-ellipsis-h {
                        .to-rem ( top, 1 );
                    }
                }
            }

            .menu-item-start-btn {
                position: relative;
                .to-rem ( left, -1 );
                float: left;
                .to-rem ( width, 79 );
                .to-rem ( height, 42 );
                background-image: url("/assets/graphics/athena-skins/ilearn/MenuItemStartBtn.png");
                background-position: 0 0;
                background-repeat: no-repeat;
                cursor: pointer;

                &:after {
                    content: '';
                }
            }

            .menu-item-start-btn.mouse-over {
                background-image: url("/assets/graphics/athena-skins/ilearn/MenuItemStartBtnOver.png");
            }
        }

        .mobile-video-play-btn {
            position: absolute;
            .to-rem ( width, 212 );
            .to-rem ( height, 212 );
            top: 50%;
            left: 50%;
            .to-rem ( margin-left, -106 );
            .to-rem ( margin-top, -106 );

            background-image: url('/assets/graphics/athena-skins/ilearn/BigPlayBtn.png');
        }

        .summary-body {
            .percentage-bar-host {

                .summary-your-score {
                    text-align: center !important;
                    .to-rem(margin-top, 24, ~"!important");
                    .to-rem(margin-bottom, 12, ~"!important");
                    text-align: center;
                    .to-rem ( margin-top, 16 );
                    .to-rem ( margin-bottom, 8 );
                }

                .percentage-bar {
                    position: relative;
                    width: 100%;
                    .to-rem ( height, 44 );
                    .percentage-fill {
                        position: absolute;
                        height: 100%;
                    }

                    .percentage-text {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        .to-rem ( padding, 9 );
                    }
                }
            }

            .summary-move-on-message {
                // .to-rem ( margin-top, 8 );
                // text-align: center;
            }
        }
    }

    @import url("athena-skins/cc.less");
    @import url("athena-skins/minimal.less");

    //  _______    _______        ________  __   ___   __    _____  ___
    // |   _  "\  |   __ "\      /"       )|/"| /  ") |" \  (\"   \|"  \
    // (. |_)  :) (. |__) :)    (:   \___/ (: |/   /  ||  | |.\\   \    |
    // |:     \/  |:  ____/      \___  \   |    __/   |:  | |: \.   \\  |
    // (|  _  \\  (|  /           __/  \\  (// _  \   |.  | |.  \    \. |
    // |: |_)  :)/|__/ \         /" \   :) |: | \  \  /\  |\|    \    \ |
    // (_______/(_______)       (_______/  (__|  \__)(__\_|_)\___|\____\)

    &.bp {
        @green-dark: #00a330;
        @green-light: #3bbd00;
        @yellow: #ffe400;

        overflow: hidden;

        .to-rem ( max-height, 776 );

        border-color: #008a28;

        .std-btn {
            position: absolute;
            .to-rem ( right, 10 );
            .to-rem ( bottom, 10 );
            .to-rem ( padding, 10 );
            .to-rem ( border-radius, 5 );
            .to-rem ( border, 1, solid var(--color-black) );

            cursor: pointer;

            color: var(--color-white);

            background: #00e644; /* Old browsers */
            background: -moz-linear-gradient(top,  #00e644 2%, #04bf34 4%, #036d22 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #00e644 2%,#04bf34 4%,#036d22 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  #00e644 2%,#04bf34 4%,#036d22 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e644', endColorstr='#036d22',GradientType=0 ); /* IE6-9 */

            .to-rem ( letter-spacing, 0.7 );

            &:hover {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#03f34a+2,04d83a+4,04942e+100 */
                background: #03f34a; /* Old browsers */
                background: -moz-linear-gradient(top,  #03f34a 2%, #04d83a 4%, #04942e 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03f34a', endColorstr='#04942e',GradientType=0 ); /* IE6-9 */
            }
        }

        .athena-close-btn {
            .to-rem ( padding, 2 5 );
            .to-rem ( margin, 5 );
            .to-rem ( border-radius, 3 );
            float: right;
            border: none;

            background-color: transparent;
            color: @green-dark;

            .to-rem ( font-size, 12 );
            text-transform: uppercase;

            &:hover {
                background-color: @green-dark;
                color: var(--color-white);
            }
        }

        .athena-header {
            .to-rem ( height, 80 );

            //background-color: @green-dark;

            .heading {
                position: absolute;
                .to-rem ( top, 32 );
                .to-rem ( right, 10 );

                .to-rem ( font-size, 20 );
            }

            .sub-heading {
                position: absolute;
                .to-rem ( top, 56 );
                .to-rem ( right, 10 );

                .to-rem ( font-size, 14 );
            }

            .logo {
                position: absolute;
                .to-rem ( margin-top, 10 );
                .to-rem ( margin-left, 10 );
                .to-rem ( height, 110 );
                -ms-interpolation-mode: bicubic;

                z-index: 20;
            }

            .header-bar {
                position: absolute;
                .to-rem ( top, 80 );
                .to-rem ( height, 24 );
                width: 100%;
                z-index: 10;

                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00e644+0,44d403+15,00a330+86,ffe400+86,ffe400+86 */
                background: #00e644; /* Old browsers */
                background: -moz-linear-gradient(top,  #00e644 0%, #44d403 15%, #00a330 86%, #ffe400 86%, #ffe400 86%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #00e644 0%,#44d403 15%,#00a330 86%,#ffe400 86%,#ffe400 86%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #00e644 0%,#44d403 15%,#00a330 86%,#ffe400 86%,#ffe400 86%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e644', endColorstr='#ffe400',GradientType=0 ); /* IE6-9 */
            }

            .assessment-timer {
                display: none;
                position: absolute;
                .to-rem ( top, 58 );
                .to-rem ( right, 5 );
                color: #FFF;
            }
        }

        footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            .to-rem ( height, 60 );
            .to-rem ( padding, 10 );
            z-index: @Z-athena-footer;

            background-color: @green-dark;

            .footer-btn {
                .to-rem ( padding, 10 );
                .to-rem ( border-radius, 5 );
                .to-rem ( border, 1, solid var(--color-black) );

                cursor: pointer;

                color: var(--color-white);

                background: #00e644; /* Old browsers */
                background: -moz-linear-gradient(top,  #00e644 2%, #04bf34 4%, #036d22 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #00e644 2%,#04bf34 4%,#036d22 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #00e644 2%,#04bf34 4%,#036d22 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e644', endColorstr='#036d22',GradientType=0 ); /* IE6-9 */

                &:hover {
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#03f34a+2,04d83a+4,04942e+100 */
                    background: #03f34a; /* Old browsers */
                    background: -moz-linear-gradient(top,  #03f34a 2%, #04d83a 4%, #04942e 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03f34a', endColorstr='#04942e',GradientType=0 ); /* IE6-9 */
                }
            }

            .menu-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( left, 15 );
            }

            .back-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 189 );
            }

            .replay-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 95 );
            }

            .next-btn {
                position: absolute;
                .to-rem ( top, 15 );
                .to-rem ( right, 15 );
            }
        }

        .play-controls {
            display: flex;
            position: absolute;
            width: 100%;
            .to-rem ( height, 24 );
            .to-rem ( left, 0 );
            .to-rem ( bottom, 60 );
            .to-rem ( padding, 2 0 );

            background-color: #060;

            .play-pause-btn {
                float: left;
                .to-rem ( margin-top, 1 );
                .to-rem ( width, 22 );
                background: none;
                border: none;

                color: var(--color-white);

                .to-rem ( font-size, 14 );
            }

            .scrub-control {
                width: 100%;
                .to-rem ( padding, 5 );

                .scrub-bar {
                    position: relative;
                    width: 100%;
                    .to-rem ( height, 10 );
                    background-color: var(--color-white);
                }

                .scrub-grab {
                    position: absolute;
                    .to-rem ( width, 20 );
                    .to-rem ( height, 10 );

                    background-color: #7B7;

                    cursor: pointer;
                }
            }

            .time-display {
                float: right;
                .to-rem ( width, 100 );
                .to-rem ( padding, 4 );

                color: var(--color-white);

                .to-rem ( font-size, 12 );
            }
        }

        .click-next-to-continue {
            position: absolute;
            .to-rem ( bottom, -90 );
            .to-rem ( right, 20 );
            .to-rem ( padding, 12 );
            .to-rem ( width, 158 );
            .to-rem ( height, 209 );

            background-image: url('/assets/graphics/athena-skins/bp/next-dude.svg');

            -webkit-transition-property        : bottom;   /* Safari and Chrome */
            transition-property        : bottom;
            -webkit-transition-duration        : .8s;      /* Safari and Chrome */
            transition-duration        : .8s;
            -webkit-transition-timing-function : ease-out; /* Safari and Chrome */
            transition-timing-function : ease-out;

            &.in-front {
                z-index: @Z-athena-click-next-in-front;
            }

            &.behind {
                z-index: @Z-athena-click-next-behind;
            }

            &.in {
                .to-rem ( bottom, 57 );
                z-index: 500;
            }

            &.out {
                .to-rem ( bottom, -220 );
                z-index: 500;
            }

            .click-next-text {
                -webkit-user-select: none; /* Chrome/Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+ */

                /* Rules below not implemented in browsers yet */
                -o-user-select: none;
                user-select: none;
            }
        }

        .athena-content {
            position: relative;
            display: none;
            opacity: 0;
            overflow: hidden;
            .to-rem ( width, 900 );
            .to-rem ( height, 500 );
            .to-rem ( margin, 60 auto );
            background-color: #F5F5F5;

            .slide-title {
                position: absolute;
                top: 40%;
                width: 100%;
                text-align: center;
            }
        }

        .athena-menu {
            .to-rem ( margin-top, 60 );

            .menu-list-container {
                .to-rem ( max-width, 900 );
                .to-rem ( height, 500 );
                margin: 0 auto;
                overflow-y: scroll;
            }

            li.menu-item {
                list-style: none;
                float: left;
                .to-rem ( margin-bottom, 16 );
            }

            li.menu-item:last-child {
                margin-bottom: 0;
            }

            .menu-item-text-bar {
                position: relative;
                float: left;
                .to-rem ( width, 521 );
                .to-rem ( height, 42 );
                .to-rem ( padding, 13 0 0 20 );
                .to-rem ( border-width, 1 1 1 5 );
                .to-rem ( border-radius, 10 0 0 10 );
                border-style: solid;

                border-color: @green-dark;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00e644+0,44d403+15,00a330+86,ffe400+86,ffe400+86 */
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00e644+2,04bf34+4,036d22+100 */
                background: #00e644; /* Old browsers */
                background: -moz-linear-gradient(top,  #00e644 2%, #04bf34 4%, #036d22 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #00e644 2%,#04bf34 4%,#036d22 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #00e644 2%,#04bf34 4%,#036d22 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e644', endColorstr='#036d22',GradientType=0 ); /* IE6-9 */

                color: #FFF;
                font-family: calibri;

                .menu-item-text {
                    font-family: "PT Sans", Arial, Helvetica;
                    white-space: nowrap;
                }

                .athena-menu-icon {
                    position: relative;
                    float: right;
                    .to-rem ( margin-top, 1 );
                    .to-rem ( margin-right, 16 );
                    color: var(--color-white);
                    .to-rem ( font-size, 14 );

                    &.fa-ellipsis-h {
                        .to-rem ( top, 1 );
                    }
                }
            }

            .menu-item-ellipsis-highlight:hover {
                position: absolute;
                width: auto;
                .to-rem ( padding, 2 );
                .to-rem ( left, 17 );
                .to-rem ( top, 10 );
                .to-rem ( border-radius, 3 );

                .to-rem ( border, 1, solid rgba(0,150,0,0.3) );

                background-color: var(--color-white);
                color: #090;

                z-index: 1;
            }

            .menu-item-start-btn {
                position: relative;
                float: left;
                .to-rem ( left, -1 );
                .to-rem ( width, 79 );
                .to-rem ( height, 42 );
                .to-rem ( padding, 12 );
                .to-rem ( border-radius, 0 10 10 0 );
                .to-rem ( border, 1, solid @green-dark );
                border-left-color: var(--color-black);
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00e644+2,04bf34+4,036d22+100 */
                background: #00e644; /* Old browsers */
                background: -moz-linear-gradient(top,  #00e644 2%, #04bf34 4%, #036d22 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #00e644 2%,#04bf34 4%,#036d22 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #00e644 2%,#04bf34 4%,#036d22 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e644', endColorstr='#036d22',GradientType=0 ); /* IE6-9 */

                color: var(--color-white);
                cursor: pointer;

                &:after {
                    content: 'Launch';
                }
            }

            .menu-item-start-btn.mouse-over {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#03f34a+2,04d83a+4,04942e+100 */
                background: #03f34a; /* Old browsers */
                background: -moz-linear-gradient(top,  #03f34a 2%, #04d83a 4%, #04942e 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  #03f34a 2%,#04d83a 4%,#04942e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03f34a', endColorstr='#04942e',GradientType=0 ); /* IE6-9 */

            }
        }

        .mobile-video-play-btn {
            position: absolute;
            .to-rem ( width, 212 );
            .to-rem ( height, 212 );
            top: 50%;
            left: 50%;
            .to-rem ( margin-left, -106 );
            .to-rem ( margin-top, -106 );

            background-image: url('/assets/graphics/athena-skins/ilearn/BigPlayBtn.png');
        }

        .summary-body {
            .percentage-bar-host {

                .summary-your-score {
                    text-align: center !important;
                    .to-rem(margin-top, 24, ~"!important");
                    .to-rem(margin-bottom, 12, ~"!important");
                }

                .percentage-bar {
                    position: relative;
                    width: 100%;
                    .to-rem ( height, 44 );
                    .percentage-fill {
                        position: absolute;
                        height: 100%;
                    }

                    .percentage-text {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        .to-rem ( padding, 9 );
                    }
                }
            }

            .summary-move-on-message {
                // .to-rem ( margin-top, 8 );
                // text-align: center;
            }
        }
    }
}
