.percentage-bar {

  background-color: #21583B;

  .percentage-fill {
    height: 100%;

    background-color: #28AD65;
  }

  .percentage-text {
    .to-rem ( top, 6 );
    color: var(--color-white);
  }
}

.insts-sites-selector {
  .to-rem ( height, 200 );

  .to-rem ( font-size, 14 );

  option[value="-"] {
    color: #999;
  }

  optgroup {
    background-color: #DEF;
    color: #68A;

    option {
      background-color: var(--color-white);
      color: #555;

      &:first-child {
        color: #79B;
      }
    }
  }
}
