.fa-1-5x {
    font-size: 1.5em;
    line-height: 1.52em;
}
.fa-stack-1-5x {
    font-size: 1.5em;
}

.highlight-box-1 {
    border: 1px solid #06C;

    background-color: #6CF;
}

.custom-dropdown {
    -webkit-appearance: none;

    background-image: url('/assets/graphics/dropdown-arrow-666.png');
    background-repeat: no-repeat;
    background-position: center right 9px;
}
