.contact-us {

  .to-rem ( margin, 20 0 0 0 );
  .to-rem ( padding, 30 );
  .to-rem ( border-width, 12 3 3 3 );
  border-style: solid;

  border-color: #CCC;
  background-color: #EEE;
  color: #222;

  h1, h2, h3, h4, h5, h6 {
    color: #222;
    .to-rem ( font-size, 30 );
  }

  input, select, textarea {
    .to-rem ( border-radius, 4 );
    .to-rem ( height, 45 );
    .to-rem ( margin, 0 );

    .to-rem ( border, 2, solid #CCC );
  }

  li {
    .to-rem ( margin, 22 0 );
  }
}


.mail-sent-success {
  padding: 20px;
  border-width: 8px 2px 2px 2px;
  border-style: solid;

  background-color: #7C7;
  color: var(--color-white);
  border-color: #6B6;

  text-align: center;

  h3 {
    .to-rem ( margin, 0 0 8 0 );
    color: var(--color-white);
  }

  p {
    margin: 0;
  }
}
