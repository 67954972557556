.user-search-fields {
    .to-rem ( margin-top, 24 );
    width: 50%;
    float: right;

    li {
        .to-rem ( margin-top, 6 );

        label {
            .to-rem ( margin-left, 2 );
        }
        input[type="text"] {
            width: 100%;
            .to-rem ( border-radius, 4 );
        }
    }
}

.user-search-btn {
    .to-rem ( margin-top, 8 );
}

.view-itr-btn {
    span {
        font-weight: bold;
    }
}

.temp-login-time {
    .to-rem ( margin-top, 4 );
}

#DT-users {
    .archived {
        td {
            background-color: #F7F7F7 !important;
            color: #BBB !important;
        }
        &:hover {
            td {
                background-color: #F2F2F2 !important;
                color: #BBB !important;
            }
        }
    }
}
