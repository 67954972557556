// |"  \    /"  | |" \  (\"   \|"  \  |" \   |"  \    /"  |     /""\    |"  |
//  \   \  //   | ||  | |.\\   \    | ||  |   \   \  //   |    /    \   ||  |
//  /\\  \/.    | |:  | |: \.   \\  | |:  |   /\\  \/.    |   /' /\  \  |:  |
// |: \.        | |.  | |.  \    \. | |.  |  |: \.        |  //  __'  \  \  |___
// |.  \    /:  | /\  |\|    \    \ | /\  |\ |.  \    /:  | /   /  \\  \( \_|:  \
// |___|\__/|___|(__\_|_)\___|\____\)(__\_|_)|___|\__/|___|(___/    \___)\_______)

@minimal-colour-xlight: #00BDFE;
@minimal-colour-light: #00ADEE;
@minimal-colour-dark : #0090C5;

&.minimal {
    overflow: hidden;

    border-color: #FFF;

    .logo {
        .to-rem ( width, 90 );
        .to-rem ( margin, 18 8 );
    }

    .std-btn {
        position: absolute;
        .to-rem ( right, 10 );
        .to-rem ( bottom, 10 );
        .to-rem ( padding, 10 );
        .to-rem ( border-radius, 5 );

        cursor: pointer;

        color: var(--color-white);

        border: none;
        border-left-style: solid;
        .to-rem ( border-left-width, 2 );

        border-left-color: #247bfd;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c2c2c+0,2c2c2c+50,191919+50 */
        background: #2c2c2c; /* Old browsers */
        background: -moz-linear-gradient(top,  #2c2c2c 0%, #2c2c2c 50%, #191919 50%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2c', endColorstr='#191919',GradientType=0 ); /* IE6-9 */

        .to-rem ( letter-spacing, 0.7 );
    }

    .athena-close-btn {
        position: absolute;
        top: 12px;
        right: 20px;
        .to-rem ( padding, 2 5 );
        .to-rem ( border-radius, 3 );
        float: right;
        border: none;

        background-color: transparent;
        color: #D2D2D2;

        .to-rem ( font-size, 16 );
        text-transform: uppercase;

        &:hover {
            background-color: #861D00;
        }
    }

    #loading-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        .to-rem ( padding, 20 );
        .to-rem ( border-radius, 20 );

        color: #FFF;
        background-color: rgba(0,0,0,0.6);
    }

    .athena-header {
        position: absolute;
        width: 100%;
        .to-rem ( height, 80 );
        .to-rem ( padding-left, 10 );

        .heading {
            .to-rem ( font-size, 24 );
            font-weight: bold;
        }

        .sub-heading {
            position: relative;
            .to-rem ( top, 0 );
            .to-rem ( left, 16 );

            .to-rem ( font-size, 14 );
        }

        .assessment-timer {
            display: none;
            position: absolute;
            .to-rem ( top, 58 );
            .to-rem ( right, 5 );
            color: #FFF;
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        .to-rem ( height, 60 );
        .to-rem ( padding, 10 );
        z-index: @Z-athena-footer;

        background-color: var(--color-white);;

        .menu-btn {
            position: absolute;
            .to-rem ( top, 15 );
            .to-rem ( left, 15 );
            opacity: 1;
            .to-rem ( padding, 8 );
            .to-rem ( border-radius, 7 );
            border: none;

            background-color: @minimal-colour-dark;
            color: #FFF;

            box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
            cursor: pointer;

            &:hover {
                background-color: @minimal-colour-xlight;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
            }

            &.disabled {
                opacity: .6;
            }
        }

        .full-screen-btn {
            position: absolute;
            .to-rem ( top, 17 );
            .to-rem ( right, 203 );
            .to-rem ( padding, 8 10 7 10 );
            .to-rem ( border-radius, 7 );
            border: none;

            background-color: @minimal-colour-dark;
            color: #FFF;

            box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
            cursor: pointer;

            &:hover {
                background-color: @minimal-colour-xlight;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
            }
        }

        .back-btn {
            position: absolute;
            .to-rem ( top, 15 );
            .to-rem ( right, 145 );
            opacity: 1;
            .to-rem ( padding, 8 );
            .to-rem ( border-radius, 7 );
            border: none;

            background-color: @minimal-colour-dark;
            color: #FFF;

            box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
            cursor: pointer;

            &:hover {
                background-color: @minimal-colour-xlight;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
            }

            &.disabled {
                opacity: .6;
            }
        }

        .replay-btn {
            position: absolute;
            .to-rem ( top, 15 );
            .to-rem ( right, 72 );
            opacity: 1;
            .to-rem ( padding, 8 );
            .to-rem ( border-radius, 7 );
            border: none;

            background-color: @minimal-colour-dark;
            color: #FFF;

            box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
            cursor: pointer;

            &:hover {
                background-color: @minimal-colour-xlight;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
            }

            &.disabled {
                opacity: .6;
            }
        }

        .next-btn {
            position: absolute;
            .to-rem ( top, 15 );
            .to-rem ( right, 15 );
            opacity: 1;
            .to-rem ( padding, 8 );
            .to-rem ( border-radius, 7 );
            border: none;

            background-color: @minimal-colour-dark;
            color: #FFF;

            box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
            cursor: pointer;

            &:hover {
                background-color: @minimal-colour-xlight;
                box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
            }

            &.disabled {
                opacity: .6;
            }
        }
    }

    *[athena-tooltip]:hover .athena-tooltip {
        opacity: 1;
    }

    .athena-tooltip {
        display: inline;
        position: absolute;
        bottom: 125%;
        .to-rem ( padding, 4 );
        .to-rem ( border-radius, 8 );
        .to-rem ( border-width, 1 1 3 1 );
        border-style: solid;
        border-color: @minimal-colour-xlight;
        background-color: @minimal-colour-dark;
        color: #ffffff;
        .to-rem ( font-size, 12 );
        z-index: 100;
        box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
        opacity: 0;

        transition-property: opacity;
        transition-duration: .3s;

        &::before {
            content: '';
            position: absolute;
            z-index: 10;
            .to-rem ( bottom, -17 );
            right: ~"calc(50% - 7px)";
            .to-rem ( height, 0 );
            .to-rem ( border-width, 7 );
            border-style: solid;
            border-color: @minimal-colour-xlight transparent transparent transparent;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 5;
            .to-rem ( bottom, -17 );
            right: ~"calc(50% - 7px)";
            .to-rem ( height, 0 );
            .to-rem ( border-width, 7 );
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
        }
    }

    /* TOOLTIPS SPECS START */

    .athena-tooltip {
        //left: NEEDS TO BE CENTRAL TO ITS PARENT;// - TAKEN CARE OF IN JS
        .to-rem ( width, 112 );
    }

    /* TOOLTIPS SPECS START */

    .click-next-to-continue {
        position: absolute;
        .to-rem ( bottom, -90 );
        .to-rem ( right, 20 );
        .to-rem ( padding, 12 );

        .to-rem ( border-radius, 10 );
        .to-rem ( border, 2, solid @minimal-colour-xlight );
        .to-rem ( border-bottom, 5, solid @minimal-colour-xlight );

        background-color: @minimal-colour-dark;
        color: var(--color-white);

        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

        &::before {
            content: '';
            position: absolute;
            z-index: 10;
            .to-rem ( bottom, -17 );
            .to-rem ( right, 25 );
            .to-rem ( height, 0 );
            .to-rem ( border-width, 7 );
            border-style: solid;
            border-color: @minimal-colour-xlight transparent transparent transparent;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 5;
            .to-rem ( bottom, -17 );
            .to-rem ( right, 25 );
            .to-rem ( height, 0 );
            .to-rem ( border-width, 7 );
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
        }

        &.in-front {
            z-index: @Z-athena-click-next-in-front;
        }

        &.behind {
            z-index: @Z-athena-click-next-behind;
        }

        .click-next-text {
            -webkit-user-select: none; /* Chrome/Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+ */

            /* Rules below not implemented in browsers yet */
            -o-user-select: none;
            user-select: none;
        }
    }

    .athena-content {
        position: absolute;
        display: none;
        opacity: 0;
        .to-rem ( top, 80 );
        .to-rem ( bottom, 60 );
        left: 0;
        right: 0;
        .to-rem ( margin, 30 20 );

        .slide-video {
            width: 100%;
        }

        .slide-title {
            position: absolute;
            top: 40%;
            width: 100%;
            text-align: center;
        }
    }

    .athena-menu {
        width: 100%;
        position: absolute;
        top: 5rem;
        bottom: 3rem;

        .menu-list-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            overflow-y: scroll;

            & > ul {
                padding: 10px 30px;
            }
        }

        li.menu-item {
            list-style: none;
            display: flex;
            padding: 8px 0;
            border-color: #B6E2F3;
            border-style: solid;
            border-width: 0 0 1px 0;
            overflow: hidden;
            cursor: pointer;

            &:hover {
                background-color: #EEF9FF;
            }
        }

        li.menu-item:last-child {
            margin-bottom: 0;
        }

        .menu-item-text-bar {
            flex-grow: 1;
            float: left;
            .to-rem ( width, 521 );
            .to-rem ( height, 48 );
            .to-rem ( padding, 13 0 0 18 );
            background-position: 0 0;
            background-repeat: no-repeat;
            color: @minimal-colour-light;
            font-family: calibri;
            font-size: 16px;

            .menu-item-text {
                font-family: "PT Sans";
                white-space: nowrap;
            }

            .athena-menu-icon {
                position: relative;
                float: right;
                .to-rem ( margin-top, 1 );
                .to-rem ( margin-right, 16 );
                color: @minimal-colour-light;
                .to-rem ( font-size, 14 );

                &.fa-ellipsis-h {
                    .to-rem ( top, 1 );
                }
            }
        }

        .menu-item-start-btn {
            .to-rem ( width, 42 );
            .to-rem ( height, 46 );
            .to-rem ( padding, 14 );
            .to-rem ( border-radius, 0 10 10 0 );
            color: @minimal-colour-dark;
            background-position: 0 0;
            background-repeat: no-repeat;
            cursor: pointer;
            font-size: 16px;

            i, svg {
                transform: rotate(-45deg);
            }
        }
    }

    .mobile-video-play-btn {
        position: absolute;
        .to-rem ( width, 212 );
        .to-rem ( height, 212 );
        top: 50%;
        left: 50%;
        .to-rem ( margin-left, -106 );
        .to-rem ( margin-top, -106 );

        background-image: url('/assets/graphics/athena-skins/ilearn/BigPlayBtn.png');
    }

    .summary-body {
        .percentage-bar-host {

            .summary-your-score {
                text-align: center;
                .to-rem ( margin-top, 16 );
                .to-rem ( margin-bottom, 8 );
            }

            .percentage-bar {
                position: relative;
                width: 100%;
                .to-rem ( height, 44 );
                .percentage-fill {
                    position: absolute;
                    height: 100%;
                }

                .percentage-text {
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    .to-rem ( padding, 9 );
                }
            }
        }

        .summary-move-on-message {
            // .to-rem ( margin-top, 8 );
            // text-align: center;
        }
    }
}
