.tab-nav {

    ul {
        .to-rem ( margin-bottom, -1 );
    }

    li {
        position: relative;
        display: inline-block;
        .to-rem ( padding, 12 );
        .to-rem ( margin, 0 );

        .to-rem ( border-radius, 10 10 0 0 );
        background-color: @secondary-colour;
        .to-rem ( border, 1, #DDD solid );
        box-shadow: inset 0px -8px 14px rgba(0, 51, 76, 0.15);

        &:hover {
            background-color: @primary-colour;

            a {
                color: #FFF;
            }
        }
    }

    .selected,
    .tab-nav-title {
        border-bottom-color: #FFF;
        background-color: #FFF;
        color: #000;

        box-shadow: none;
    }

    .selected {
        a {
            color: @primary-colour;
        }
    }

    a {
        color: #FFF;
        text-decoration: none;

        cursor: pointer;
    }
}

.tab-section {
    .to-rem ( padding, 20 );
    .to-rem ( border-radius, 0 20 20 20 );
    background-color: #FFF;
    .to-rem ( border, 1, #DDD solid );
}
