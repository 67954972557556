// - STOP BODY BEING SCROLLABLE FOR MODALS
html, body {
    height: 100%;
    color: @primary-font-colour;
    font-family: Calibri;
}

h1 {
    font-size: 24px;
    font-weight: bold;
}

.overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    // background-color: rgba(0, 0, 0, 0.8);
    //
    // .overlay-content {
        //     height: 100%;
        //     overflow: scroll;
    // }
}

.background-content{
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
// - END STOP BODY BEING SCROLLABLE FOR MODALS

body {
    .width-auto {
        width: auto;
    }

    &.no-select {
        -webkit-user-select: none; /* Chrome/Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */

        /* Rules below not implemented in browsers yet */
        -o-user-select: none;
        user-select: none;
    }

    .background-content > main {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }

    *:focus {
        outline: none !important;
    }

    .generic-blackout {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1100;

        width: 100%;
        height: 100%;

        background-color: var(--color-black);
        opacity: .5;
    }

    .overlays {
        display: none;
        position: fixed;
        z-index: @Z-overlays;
        width: 100%;
        height: 100%;

        .blackout {
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 1100;

            width: 100%;
            height: 100%;

            background-color: var(--color-black);
            opacity: .5;
        }

        .popup-flex {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: flex-end;
            justify-content: center;

            @media (min-width: 768px) {
                align-items: center;
            }

            .popup {
                display: none;
                position: relative;
                top: 100%;
                .to-rem ( border-radius, 20 );
                z-index: 1200;

                width: 100%;
                height: 100%;
                .to-rem ( max-width, 1000 );
                .to-rem ( max-height, 90% );
                .to-rem ( border, 1, solid #222 );

                header {
                    display: block;
                    float: left;
                    width: 100%;
                    .to-rem ( border-radius, 20 20 0 0 );

                    background-color: #FFF;
                }

                main.popup-content {
                    float: left;
                    .to-rem ( border-radius, 0 0 20 20 );
                    background-color: #FFF;
                }

                .popup-toolbar {
                    display: none;
                    float: left;
                    width: 100%;
                    .to-rem ( margin, 0 );

                    background-color: var(--color-white);

                    .popup-toolbar-btn {
                        .to-rem ( padding, 6 );
                        .to-rem ( border, 0 );

                        background: transparent;
                        color: #999;

                        text-shadow: none;

                        &:hover {
                            color: var(--color-black);
                        }

                        i.fa {
                            .to-rem ( font-size, 18 );
                        }
                    }
                }

                footer {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    .to-rem ( height, 32 );

                    .to-rem ( border-radius, 0 0 20 20 );

                    background-color: #FFF;
                }
            }

        }

        .athena-flex {
            box-sizing: border-box;
            display: flex;
            .to-rem ( padding, 30 );
            width: 100%;
            height: 100%;
            align-items: flex-end;
            justify-content: center;
            position: relative;
            z-index: @Z-athena-flex;

            @media (min-width: 768px) {
                align-items: center;
            }

            .athena-in-popup {
                display: none;
                .to-rem ( border-radius, 20 );
                position: relative;
                z-index: 1200;
                top: 100%;

                width: 100%;
                height: 100%;
                border-style: solid;

                background-color: var(--color-white);
            }

        }
    }

    .content-pane {
        flex-grow: 1;
        height: ~"calc(100vh - 100px)";
        overflow-y: scroll;
        background-color: #E7E7E7;
    }

    .hidden {
        visibility: hidden;
    }

    .location-selector {
        width: 100%;
        .to-rem ( height, 300 );
        .to-rem ( padding, 5 20 );

        optgroup,
        option {
            .to-rem ( padding, 10 0 );
            .to-rem ( border-top, 1, solid #F7F7F7 );
        }

        optgroup {
            color: #64AED8;

            .to-rem ( font-size, 17 );
            font-weight: 600;
        }

        option {
            color: var(--color-black);

            .to-rem ( font-size, 15 );
            font-weight: normal;

            &:first-child {
                .to-rem ( margin-top, 10 );
                font-weight: 600;
            }
        }
    }
}

body.no-header {
    .content-pane {
        height: 100vh;
    }
}

.card {
    padding: 46px;
    border-radius: 20px;
    border: none;
    background-color: #FFF;
}

.group-panel {
    .to-rem ( border-radius, 24 );
    background-color: #F3F3F3;

    .card {
        height: 100%;

        h2 {
            .to-rem ( font-size, 22 );
            font-weight: bold;
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

// @GLOSSARY
.alphanum-nav {
    .to-rem ( margin, 0 auto );
    .to-rem ( width, 420 );

    a {
        text-decoration: none;
    }

    .alphanum-nav-showing {
        .to-rem ( padding, 2 );

        background-color: #62BAD7;
        color: var(--color-white);
    }
}

.photo-placeholder {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    .to-rem ( padding, 2 );

    background-color: #DDD;
    color: #AAA;

    &:not(&.no-border) {
        border-style: solid;
        .to-rem ( border-width, 3 );
        border-radius: 50%;

        border-color: @secondary-colour;
    }

    font-family: Calibri, Arial, Helvetica;
    .to-rem ( font-size, 52 );
    font-weight: 700;

    &.no-border {
        border: none;
    }

    .photo-placeholder-initials {
        .to-rem ( height, 40 );
    }
}

.image-upload-choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;

    > * {
        .to-rem ( margin, 5 0 );
    }

    > button {
        padding: 12px 24px !important;
        font-size: 19px !important;
    }

    > .or {
        .to-rem ( padding, 15 );

        color: #CCC;

        font-style: italic;
        .to-rem ( font-size, 20 );
    }
}

.inline-upload-photo {
    width: 100%;
    height: 100%;
    .to-rem ( margin-left, -187 );
    .to-rem ( padding-left, 210 );

    .upload_form {
        height: 100%;

        label[for="file_upload"] {
            position: relative;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;

            .to-rem ( margin-top, 0 );
            width: 50%;
            height: 100%;
        }

        input[type="file"] {
            width: 50%;
            height: 100%;
        }

        .upload-progress-host {
            float: left;
            width: 48%;
            margin-left: 2%;

            .file_to_upload {
                margin-top: 0 !important;
            }
        }
    }
}

.photo-capture {
    width: 100%;
    height: 100%;
    .to-rem ( margin-left, -170 );
    .to-rem ( padding-left, 190 );

    .video-cuttoff {
        float: left;
        overflow: hidden;
        .to-rem ( width, 170 );
        .to-rem ( height, 186 );

        .photo-capture-video {
            .to-rem ( max-width, 250 );
            .to-rem ( width, 250 );
            .to-rem ( margin-left, -40 );
        }
    }

    canvas.photo-capture-canvas {
        float: left;
        display: none;
        .to-rem ( width, 250 );
        .to-rem ( height, 186 );
    }

    .profile-photo-capture-img {
        float: left;
        .to-rem ( width, 170 );
        .to-rem ( height, 170 );
    }

    .photo-camera-controls {
        float: left;
        .to-rem ( padding, 15 0 );
        .to-rem ( margin-right, 10 );
        .to-rem ( border-radius, 0 8 8 0 );
        .to-rem ( border-width, 1 );
        border-style: solid;

        border-color: #DDD;
        background-color: #EEE;

        .photo-camera-control-btn {
            .to-rem ( width, 80 );
            .to-rem ( margin-bottom, 19 );
            border: none;

            background-color: transparent;

            .to-rem ( font-size, 34 );

            &:last-child {
                .to-rem ( margin-bottom, 1 );
            }

            &.active {
                color: #444;

                &:hover {
                    color: #7B7;
                }
            }

            &.inactive {
                color: #CCC;

                &:hover {
                    cursor: no-drop;
                }
            }
        }
    }
}

// - FONT STYLING
.em { font-style: italic !important; }

// - TEXT COLOURS
.grey { color: #666 !important; }
.l-grey { color: #999 !important; }
.ll-grey { color: #CCC !important; }

// - BG COLOURS
.bg-grey,
.bg-grey td { background-color: #666 !important; }
.bg-l-grey,
.bg-l-grey td { background-color: #999 !important; }
.bg-ll-grey,
.bg-ll-grey td { background-color: #CCC !important; }

.bg-666,
.bg-666 td { background-color: #666 !important; }
.bg-999,
.bg-999 td { background-color: #999 !important; }
.bg-CCC,
.bg-CCC td { background-color: #CCC !important; }
.bg-DDD,
.bg-DDD td { background-color: #DDD !important; }
.bg-EEE,
.bg-EEE td { background-color: #EEE !important; }
.bg-FFF,
.bg-FFF td { background-color: #FFF !important; }

.no-hyphenation {
    hyphens: none !important;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted;
}

.notification {
    .to-rem ( padding, 8 );
    .to-rem ( margin-bottom, 12 );
    .to-rem ( border-radius, 4 );

    text-align: center;

    &.red-notification {
        background-color: #C00;
        color: #FFF;
    }
}

.warning-text {
    .to-rem ( padding, 5 16 3 16 );
    .to-rem ( border-radius, 5 );
    .to-rem ( border-width, 1 );
    border-style: solid;
    border-color: #CC6767;
    background-color: #FCC;
    color: #B13E3E;

    a {
        color: #38849E;
    }
}

.scheduled-maintenance-soon {
    .to-rem ( padding, 20 );
    background-color: #FB0;
    color: #FFF;
    .to-rem ( font-size, 18 );
    font-weight: bold;
    text-align: center;
}

.br-nw-0 { .to-rem ( border-top-left-radius, 0 ); }
.br-ne-0 { .to-rem ( border-top-right-radius, 0 ); }
.br-se-0 { .to-rem ( border-bottom-right-radius, 0 ); }
.br-sw-0 { .to-rem ( border-bottom-left-radius, 0 ); }
.br-nw-p { .to-rem ( border-top-left-radius, @border-radius ); }
.br-ne-p { .to-rem ( border-top-right-radius, @border-radius ); }
.br-se-p { .to-rem ( border-bottom-right-radius, @border-radius ); }
.br-sw-p { .to-rem ( border-bottom-left-radius, @border-radius ); }


@media (min-width: 576px) { /* sm */
  .rounded-sm-0 { border-radius: 0 !important; }
  .rounded-sm-1 { border-radius: 0.25rem !important; }
  .rounded-sm-2 { border-radius: 0.375rem !important; }
  .rounded-sm-3 { border-radius: 1rem !important; }
  .rounded-sm-4 { border-radius: 2rem !important; }
}

@media (min-width: 768px) { /* md */
  .rounded-md-0 { border-radius: 0 !important; }
  .rounded-md-1 { border-radius: 0.25rem !important; }
  .rounded-md-2 { border-radius: 0.375rem !important; }
  .rounded-md-3 { border-radius: 1rem !important; }
  .rounded-md-4 { border-radius: 2rem !important; }
}

@media (min-width: 992px) { /* lg */
  .rounded-lg-0 { border-radius: 0 !important; }
  .rounded-lg-1 { border-radius: 0.25rem !important; }
  .rounded-lg-2 { border-radius: 0.375rem !important; }
  .rounded-lg-3 { border-radius: 1rem !important; }
  .rounded-lg-4 { border-radius: 2rem !important; }
}

@media (min-width: 1200px) { /* xl */
  .rounded-xl-0 { border-radius: 0 !important; }
  .rounded-xl-1 { border-radius: 0.25rem !important; }
  .rounded-xl-2 { border-radius: 0.375rem !important; }
  .rounded-xl-3 { border-radius: 1rem !important; }
  .rounded-xl-4 { border-radius: 2rem !important; }
}

@media (min-width: 1400px) { /* xxl */
  .rounded-xxl-0 { border-radius: 0 !important; }
  .rounded-xxl-1 { border-radius: 0.25rem !important; }
  .rounded-xxl-2 { border-radius: 0.375rem !important; }
  .rounded-xxl-3 { border-radius: 1rem !important; }
  .rounded-xxl-4 { border-radius: 2rem !important; }
}

