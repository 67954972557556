body {
    .background-content {
        > header {
            width: 100%;
            height: 100px;
            background: #FFF;
            border-bottom: 1px solid #CCC;

            .menu-icon {
                width: 40px;
                margin: 0 auto;
                display: block;
            }

            a.header-logo {
                display: block;
                width: 100px;
                height: 80px;
                background: url(https://reynolds.oncalm.cloud/assets/css/skin-overwrites/calmcloud/calm-cloud-logo-744x150.png) no-repeat;
                background-size: 180px;
                background-position: 16px 24px;
                font-size: 0;
            }

            @media (min-width: 1025px) {
                a.header-logo {
                    width: 280px;
                    background-size: 250px;
                    background-position: 16px;
                }
            }

            .user-menu {
                list-style: none;
                text-align: right;
                z-index: 1000;

                i.fa-caret-down {
                    margin-left: 16px;
                }

                > li {
                    margin: 0;
                    cursor: pointer;

                    &:hover {
                        ul {
                            display: block;
                            text-align: left;
                        }
                    }

                    ul {
                        display: none;
                        list-style: none;
                        padding: 16px 16px 8px 16px;
                        border-radius: 0 0 10px 10px;
                        background-color: #FFF;
                        box-shadow: 0px 7px 9px rgba(0,0,0,0.1);

                        li {
                            border-radius: 6px;
                            margin: 0 0 4px 0;
                            padding: 6px 12px;
                            cursor: pointer;

                            a {
                                color: #003;
                                text-decoration: none;
                            }

                            &:hover {
                                background-color: #003;
                                color: #FFF;

                                a {
                                    color: #FFF;
                                }
                            }

                            i, svg {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
