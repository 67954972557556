body {
    margin: 0;
    background-color: #EEE;
    font-family: 'Lato', 'Gill Sans';
    font-size: 18px;

    .background-content > main > nav {
        position: fixed;
        left: -304px;
        z-index: 1200;
        overflow-y: scroll;
        width: 300px;
        min-width: 300px;
        background-color: var(--secondary-colour);
        color: #FFF;
        height: ~"calc(100vh - 100px)";

        @media (min-width: 1025px) {
            position: static;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-left: 0;
            }
        }

        > ul {
            > li {
                label, div, a {
                    display: flex;
                    color: #FFF;
                    text-decoration: none;

                    .nav-icon {
                        flex-grow: 0;
                        width: 24px;
                        margin: 15px 10px 10px 14px;
                        text-align: center;
                    }

                    .nav-text {
                        flex-grow: 1;
                        padding: 12px 16px 12px 0;
                    }

                    .nav-plus-cross {
                        flex-grow: 0;
                        padding: 12px 16px 12px 0;

                        svg {
                            transition: .6s;
                        }
                    }
                }

                &.sub-header {
                    cursor: pointer;

                    ul {
                        overflow-y: hidden;
                        transition: height .6s;
                    }
                }

                &.cc-blue {
                    &.sub-header {
                        background-color: #00ADEF;
                    }

                    // Option 1
                    ul li {
                        background-color: #4CCA4C;
                    }
                    // Option 2
                    ul li {
                        background-color: #DCFADC;

                        a {
                            color: var(--secondary-colour);
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: var(--secondary-colour);
                        }
                    }
                }

                &.navy {
                    &.sub-header {
                        background-color: var(--secondary-colour);
                    }

                    // Option 1
                    ul li {
                        background-color: #4CCA4C;
                    }
                    // Option 2
                    ul li {
                        background-color: #DCFADC;

                        a {
                            color: #003;
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: #003;
                        }
                    }
                }

                &.green {
                    &.sub-header {
                        background-color: #3CBA3C;
                    }

                    // Option 1
                    ul li {
                        background-color: #4CCA4C;
                    }
                    // Option 2
                    ul li {
                        background-color: #DCFADC;

                        a {
                            color: #003;
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: #003;
                        }
                    }
                }

                &.orange {
                    &.sub-header {
                        background-color: #FFA216;
                    }

                    // Option 1
                    ul li {
                        background-color: #FFB226;
                    }
                    // Option 2
                    ul li {
                        background-color: #FFE2A6;

                        a {
                            color: #003;
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: #003;
                        }
                    }
                }

                &.pink {
                    &.sub-header {
                        background-color: #EE0052;
                    }

                    // Option 1
                    ul li {
                        background-color: #FE1062;
                    }
                    // Option 2
                    ul li {
                        background-color: #FEE0E2;

                        a {
                            color: #003;
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: #003;
                        }
                    }
                }

                &.blue {
                    &.sub-header {
                        background-color: #12C1E6;
                    }

                    // Option 1
                    ul li {
                        background-color: #22D1F6;
                    }
                    // Option 2
                    ul li {
                        background-color: #C2F0F6;

                        a {
                            color: #003;
                        }
                    }
                    // Option 3
                    ul li {
                        background-color: #EEE;

                        a {
                            color: #003;
                        }
                    }
                }
            }
        }

        .version {
            position: absolute;
            bottom: 4px;
            left: 4px;
            color: #312f71;
            font-size: 12px;
        }
    }

    &.no-header {
        .background-content > main > nav {
            height: 100vh;
        }
    }
}
