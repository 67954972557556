.ilt-sessions {
    .to-rem ( margin-top, 30 );
}

.awarding-body-logo-field {
    .to-rem ( padding, 20 0 20 20 );
    .to-rem ( height, 220 );
    .to-rem ( border-radius, 10 );
    .to-rem ( border-width, 1 );
    border-style: solid;

    border-color: #CCC;
    background-color: var(--color-white);
}

.awarding-body-logo {
    .to-rem ( border-radius, 10 );
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.mdd-ilt-assessments {
    .to-rem ( height, 150 );
}
