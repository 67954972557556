.top-level-category {
  .to-rem ( height, 250 );
  .to-rem ( margin-bottom, 10 );
  .to-rem ( padding, 16 );
  .to-rem ( border-radius, 5 );
  overflow: hidden;

  background-color: var(--color-white);

  a {
    &.help-category-heading-link {

      text-decoration: none !important;

      h4 {
        color: #0578A7;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h4 {
    .to-rem ( margin-bottom, 16 );
  }

  ul {
    margin-bottom: 0;
  }

  li {
    .to-rem ( margin-left, 20 );
    .to-rem ( margin-bottom, 8 );
    list-style: square;

    a {
      color: #0578A7;

      text-decoration: none;
      font-style: italic;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.help-search-form {
  width: 100%;
  .to-rem ( padding, 10 2 12 50 );
  .to-rem ( margin-bottom, 20 );
  .to-rem ( border-radius, 10 );

  background-color: var(--color-white);

  .help-search-input {
    width: 100%;
    .to-rem ( margin-left, -40 );
  }
}

.help-article {
  .to-rem ( padding, 60 );
  .to-rem ( margin-bottom, 20 );

  background-color: var(--color-white);
  color: #777;

  font-family: "Georgia";
  .to-rem ( font-size, 14 );
  .to-rem ( line-height, 24 );

  .title {
    color: @std-label-colour;
  }

  .sub-title {
    color: @std-label-colour;
  }

  .last-edited-ts {
    float: right;

    font-style: italic;
    .to-rem ( font-size, 12 );
  }

  ul {
    list-style: disc;

    li {
      .to-rem ( margin, 10 0 10 20 );
    }
  }
}

.screenshot {
  .to-rem ( padding, 20 );
  .to-rem ( margin, 20 0 );

  .to-rem ( border, 5, solid #EEE );
  background-color : #F9F9F9;
}

.search-term-highlight {
  background-color: #FD0;
  color: var(--color-black);
}

.search-result-excerpt {
  .to-rem ( margin-bottom, 0 );
  .to-rem ( padding, 8 );
  .to-rem ( border-radius, 4 );

  .to-rem ( border, 1, solid #C2E5F7 );

  background-color: #EDFBFF;
  color: #52A5bD;
}
