.messenger {

  overflow: hidden;
  z-index: @Z-messenger;

  .messenger-display {
    .to-rem ( height, 532 );
    .to-rem ( border, 2, solid #2A57A9 );
    background-color: #FCFCFC;
  }

  .folders {
    float: left;

    @media (min-width: 768px) {
      width: 20%;
    }

    li {
      .to-rem ( margin, 0 0 2 0 );
      .to-rem ( border-width, 0 0 1 0 );
      border-style: solid;
      border-color: #E9ECEF;
      background-color: #FCFCFC;

      a {
        display: block;
        .to-rem ( padding, 9 );
        text-decoration: none;

        cursor: pointer;
      }
    }
  }

  .right-pane {
    position: relative;
    float: right;
    width: 80%;
    height: 100%;
    overflow: scroll;

    .primary-functions {
      .to-rem ( padding, 10 );
      background-color: @top-level-link-colour;

      a {
        .to-rem ( margin-top, 2 );
        color: var(--color-white);
        text-decoration: none;

        .to-rem ( margin-right, 15 );
      }
    }

    .message-view {
      position: absolute;
      z-index: @Z-messenger-msg;
      background-color: #EEE;
    }

    .message-list {
      position: absolute;
      z-index: @Z-messenger-msg-list;
      .to-rem ( top, 36 );
      width: 100%;
      .to-rem ( margin-bottom, 0 );
      .to-rem ( border-width, 0 0 0 2 );
      border-style: solid;
      border-color: @top-level-link-colour;
      background-color: var(--color-white);

      .dt-message-list {
        display: none;
      }

      td {
        .to-rem ( padding, 14 );
        vertical-align: middle;
        box-sizing: border-box;
        cursor: pointer;
      }

      .dataTables_info {
        .to-rem ( padding-left, 8 );
      }
    }

    .new-message {
      position: absolute;
      top: 100%;
      z-index: @Z-messenger-new-msg;
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      .to-rem ( border-left, 2, solid @top-level-link-colour ) ;

      header {
        .to-rem ( padding, 8 );
        .to-rem ( border-top, 1, solid #1E4A98 );
        background-color: #3664B7;
        color: var(--color-white);

        h4 {
          float: left;
          display: inline;
          margin: 0;

          color: var(--color-white);

          .to-rem ( font-size, 18 );
        }

        .buttons {
          float: right;

          label {
            .to-rem ( margin-right, 12 );
          }

          a {
            .to-rem ( margin, 0 12 );
            color: var(--color-white);
            text-decoration: none;
          }
        }
      }

      .new-msg-fields {
        .to-rem ( margin-top, 5 );

        li {
          position: relative;
          .to-rem ( margin, 0 0 1 0 );
          .to-rem ( padding, 1 6 );

          .add-recipient-search {
            display: inline;
            float: left;
            position: relative;
            top: 100%;
            .to-rem ( padding-top, 0 );
            .to-rem ( padding-right, 10 );
            .to-rem ( padding-bottom, 0 );
            .to-rem ( padding-left, 10 );
            .to-rem ( margin-top, -2 );
            width: 100%;
            height: 0;
            z-index: @Z-messenger-to-box-off;
            opacity: 0;

            background-color: #3664B7;
            color: var(--color-white);

            select {
              .to-rem ( border-radius, 0 );
            }
          }
        }
      }

      .field-label {
        float: left;
        .to-rem ( width, 110 );
        .to-rem ( padding, 10 8 );
        text-align: right;
      }

      .field {
        overflow: hidden;

        .new-msg-to-field,
        .new-msg-subject-field,
        .new-msg-attachments-field {
          width: 100%;
          .to-rem ( padding, 8 );
          .to-rem ( border, 1, solid #EEE );
          background-color: var(--color-white);
        }

        .new-msg-to-field {
          .recipient {
            display: inline;
            .to-rem ( margin-right, 4 );
            .to-rem ( padding, 4 5 2 5 );
            .to-rem ( border-radius, 4 );
            .to-rem ( border, 1, solid #99B9F3 );
            background-color: #CCE7FB;
            color: #3664B7;

            .to-rem ( font-size, 13 );

            .remove-recipient-btn {
              .to-rem ( margin-left, 4 );
              .to-rem ( font-size, 12 );
            }
          }
        }

        .new-msg-subject-field {
          margin-top: 0;
          .to-rem ( padding, 7 8 );
        }

        .search-results {
          position: relative;
          width: 100%;
          .to-rem ( height, 150 );
          .to-rem ( margin, -1 0 0 0 );
          border-top-style: dashed;


          option {
            .to-rem ( padding, 4 );
            color: #CCC;
            .to-rem ( font-size, 13 );
            font-weight: 100;
          }
        }
      }
    }
  }

}
